.pagetype--istripsearch .ewr_headerimage {
  max-height: 400px;
  overflow: hidden;
}
.pagetype--istripsearch .countrypage__intro {
  padding: 50px 0;
}
@media (max-width: 991.8px) {
  .pagetype--istripsearch .countrypage__intro {
    padding: 25px 0;
  }
  .pagetype--istripsearch .countrypage__intro .row {
    margin: 0;
  }
  .pagetype--istripsearch .countrypage__intro .row .col-lg-8 {
    margin-top: -75px;
    background-color: var(--white);
    padding: 20px;
  }
}
@media (max-width: 991.8px) {
  .pagetype--istripsearch .countrypage__openstreetmap__image {
    display: none;
  }
}
@media (min-width: 992px) {
  .pagetype--istripsearch .countrypage__openstreetmap {
    margin-top: -80px;
  }
}
.pagetype--istripsearch .countrypage__openstreetmap .btn {
  margin-top: 20px;
}
@media (min-width: 992px) {
  .pagetype--istripsearch .countrypage__openstreetmap .btn {
    margin-top: -20px;
  }
}
.pagetype--istripsearch .countrypage__longdescription {
  padding: 0 0 50px 0;
}
@media (max-width: 991.8px) {
  .pagetype--istripsearch .countrypage__trips__result .ewr__trips__filter {
    display: none;
  }
}
.pagetype--istripsearch .countrypage__cta__row {
  padding: 100px 0;
  background-color: var(--primary-light);
}
.pagetype--istripsearch .countrypage__cta__row h4 {
  font-size: 2rem;
}