.ewr__featured__trips {
  position: relative;
  overflow: hidden;
}
@media (min-width: 992px) {
  .ewr__featured__trips.noloop .splide__fade {
    display: none;
  }
}
.ewr__featured__trips .splide__fade {
  position: absolute;
  z-index: 1;
  top: 0%;
  bottom: 0%;
  left: 0%;
  right: 0%;
  height: 100%;
  pointer-events: none;
}
.ewr__featured__trips .splide__fade-left {
  background-image: linear-gradient(to right, #ffffff, rgba(255, 255, 255, 0) 15%);
}
.ewr__featured__trips .splide__fade-right {
  background-image: linear-gradient(to left, #ffffff, rgba(255, 255, 255, 0) 15%);
}
@media (max-width: 767px) {
  .ewr__featured__trips .container-xl {
    padding-left: 0;
    padding-right: 0;
  }
}
.ewr__featured__trips .handwritten_title {
  color: var(--primary);
}
.ewr__featured__trips .splide .splide__arrows .splide__arrow {
  background-color: var(--white);
  opacity: 1;
  width: 100px;
  border-radius: 30px;
  height: 50px;
  z-index: 2;
  position: absolute;
}
.ewr__featured__trips .splide .splide__arrows .splide__arrow .las {
  color: var(--primary);
  font-size: 21px;
  position: relative;
  transition: transform 0.3s ease;
  transform: translateX(0);
}
.ewr__featured__trips .splide .splide__arrows .splide__arrow .las.la-arrow-left {
  left: 20px;
}
.ewr__featured__trips .splide .splide__arrows .splide__arrow .las.la-arrow-right {
  right: 20px;
}
.ewr__featured__trips .splide .splide__arrows .splide__arrow--prev {
  left: -50px;
}
.ewr__featured__trips .splide .splide__arrows .splide__arrow--prev:hover .la-arrow-left {
  animation: slideLeft 0.8s ease-in-out infinite;
}
.ewr__featured__trips .splide .splide__arrows .splide__arrow--next {
  right: -50px;
}
.ewr__featured__trips .splide .splide__arrows .splide__arrow--next:hover .la-arrow-right {
  animation: slideRight 0.8s ease-in-out infinite;
}
.ewr__featured__trips .splide .splide__track .splide__list .splide__slide .ewr__featured__trips__item {
  text-align: center;
}
.ewr__featured__trips .splide .splide__track .splide__list .splide__slide .ewr__featured__trips__item a {
  text-decoration: none;
}
.ewr__featured__trips .splide .splide__track .splide__list .splide__slide .ewr__featured__trips__item__img {
  overflow: hidden;
  margin: 0 auto;
}
.ewr__featured__trips .splide .splide__track .splide__list .splide__slide .ewr__featured__trips__item__img img {
  object-fit: cover;
  height: 100%;
  margin-bottom: 20px;
}
.ewr__featured__trips .splide .splide__track .splide__list .splide__slide .ewr__featured__trips__item__title {
  display: block;
  font-size: 28px;
  margin-bottom: 5px;
  font-family: var(--theme-heading-font);
  color: var(--font-color);
}
.ewr__featured__trips .splide .splide__track .splide__list .splide__slide .ewr__featured__trips__item__meta {
  display: block;
  font-size: 21px;
  margin-bottom: 10px;
  color: var(--font-color);
}