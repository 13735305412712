#sidenavSearchEwr {
  width: 320px !important;
}
#sidenavSearchEwr .search__title {
  font-family: var(--theme-heading-font);
  color: var(--dark);
  font-size: 21px;
  display: block;
  margin-bottom: 1rem;
}
#sidenavSearchEwr .form-control,
#sidenavSearchEwr .form-select {
  border-color: var(--border-color);
}

#sidenavEwr {
  width: 320px !important;
}
#sidenavEwr .offcanvas-header {
  background-color: var(--white);
}
@media (max-width: 991.98px) {
  #sidenavEwr .offcanvas-header .logo img {
    width: 175px;
  }
}
#sidenavEwr .offcanvas-body {
  padding: 0;
  overflow-y: auto;
  display: flex;
  width: 100%;
}
#sidenavEwr .offcanvas-body nav {
  width: 100%;
}
#sidenavEwr .offcanvas-body #ewr-offcanvas-navbar {
  height: 100%;
  background-color: transparent;
  -webkit-transition: -webkit-transform 0.25s ease;
  transition: transform 0.25s ease;
  -webkit-transform: translate(-320px, 0);
  -ms-transform: translate(-320px, 0);
  transform: translate(-320px, 0);
  -webkit-transform: translate3d(0, 0, 0);
  overflow-x: hidden;
  height: 100%;
}
#sidenavEwr .offcanvas-body #ewr-offcanvas-navbar ul {
  line-height: 50px;
  text-decoration: none;
  padding: 0;
  list-style: none;
  border-top: 1px solid var(--border-color-light);
}
#sidenavEwr .offcanvas-body #ewr-offcanvas-navbar ul li.nav-item {
  padding: 0;
  border-bottom: 1px solid var(--border-color-light);
  flex-direction: row;
  align-items: center;
  display: flex;
}
#sidenavEwr .offcanvas-body #ewr-offcanvas-navbar ul li span.sub-heading {
  padding: 0 1rem;
  line-height: 32px;
  font-size: 16px;
  color: var(--primary);
}
#sidenavEwr .offcanvas-body #ewr-offcanvas-navbar ul li.sub-heading {
  color: var(--primary);
  font-family: var(--theme-font);
  text-decoration: none;
  width: 100%;
  clear: both;
  font-weight: 600;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
  display: flex;
  border-top: 1px solid var(--border-color-light);
  border-bottom: 1px solid var(--border-color-light);
}
#sidenavEwr .offcanvas-body #ewr-offcanvas-navbar ul li.sub-heading span {
  padding: 0 1rem;
  display: inline-block;
  color: var(--dark);
  width: 100%;
}
#sidenavEwr .offcanvas-body #ewr-offcanvas-navbar ul li.sub-heading span:empty {
  display: none;
}
#sidenavEwr .offcanvas-body #ewr-offcanvas-navbar ul li a {
  display: block;
  width: 100%;
  padding: 0 1rem;
  text-decoration: none;
  color: var(--dark);
  line-height: 36px;
}
#sidenavEwr .offcanvas-body #ewr-offcanvas-navbar label {
  padding: 0.5rem 1rem;
  display: block;
  cursor: pointer;
}
#sidenavEwr .offcanvas-body #ewr-offcanvas-navbar label.toggle-sub {
  margin-right: -1px;
  width: 50px;
  height: 50px;
  border: 1px solid var(--border-color-light);
  border-bottom: 0;
  border-top: 0;
  cursor: pointer;
  line-height: 36px;
  text-align: center;
  color: var(--dark);
}
#sidenavEwr .offcanvas-body #ewr-offcanvas-navbar .sub-nav-check {
  position: absolute;
  top: -9999px;
  left: -9999px;
}
#sidenavEwr .offcanvas-body #ewr-offcanvas-navbar .sub-nav-check:checked ~ [id$=-sub] {
  -webkit-transform: translate(-320px, 0);
  -ms-transform: translate(-320px, 0);
  transform: translate(-320px, 0);
}
#sidenavEwr .offcanvas-body #ewr-offcanvas-navbar .sub-nav {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 320px;
  z-index: 9999;
  left: 320px;
  background-color: var(--white);
  -webkit-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  transform: translate(0, 0);
  -webkit-transition: -webkit-transform 0.25s ease;
  transition: transform 0.25s ease;
  margin-top: -1px;
  -webkit-transform: translate3d(0, 0, 0);
}
#sidenavEwr .offcanvas-body #ewr-offcanvas-navbar .sub-nav li.sub-heading:not(:first-child) {
  display: none;
}
#sidenavEwr .offcanvas-body #ewr-offcanvas-navbar .sub-nav .toggle {
  margin-right: -1px;
  width: 50px;
  height: 50px;
  border: 1px solid var(--border-color-light);
  border-bottom: 0;
  border-top: 0;
  cursor: pointer;
  line-height: 36px;
  text-align: center;
}
#sidenavEwr .offcanvas-body .navbar-nav .nav-item {
  padding: 10px 0;
}
#sidenavEwr .offcanvas-body .navbar-nav .nav-item a {
  font-family: var(--theme-font);
  padding-right: 50px !important;
  text-decoration: none;
  font-size: 16px;
}
#sidenavEwr .offcanvas-body .navbar-nav.second-menu {
  opacity: 0.6;
}
#sidenavEwr .offcanvas-body .navbar-nav.second-menu .nav-item {
  padding: 7px 0;
}
#sidenavEwr .offcanvas-body .navbar-nav.second-menu .nav-item a {
  font-size: 16px;
}