/* @import "~dompack/components/dialog/mixins"; */
:root {
  --color-inputtext: #424242;
  --color-disabled: #BFBFBF;
  --bgcolor-disabled: #F2F2F2;
  --boxshadow-focus: 0 0 0 3px rgba(6, 65, 82, 0.14);
  --label-leftspace: 195px;
  --placeholder-color: #87888a;
  --onecolumntogglewidth: 900px;
}

.wh-form__fieldgroup:not(.wh-form__fieldgroup--hidden) {
  display: flex;
}

.wh-form__fieldgroup {
  align-items: baseline;
}

.wh-form__label, .wh-form__optiondata,
.wh-form__optionlabel,
.wh-form__subfieldlabel {
  display: inline-block;
}

.wh-form__label {
  flex-shrink: 0;
}

.wh-form__fields {
  flex: 1 0 0px;
}

.wh-form__fieldline {
  width: 100%;
  align-items: baseline;
  flex-wrap: wrap;
}

.wh-form__fieldline:not(.wh-form__fieldline--hidden) {
  display: inline-flex;
}

.wh-form__fieldline--nowrap:not(.wh-form__fieldline--hasinfotext) {
  flex-wrap: nowrap;
}

.wh-form__fieldgroup--horizontal .wh-form__fields {
  display: flex;
  flex-wrap: wrap;
}
.wh-form__fieldgroup--horizontal .wh-form__fieldline {
  flex: none;
  width: auto;
  max-width: 100%;
}

.wh-form__infotextwrapper {
  flex: 0 0 100%;
}

.wh-form__optiondata {
  display: inline-flex;
  width: 100%;
  align-items: baseline;
  flex-grow: 1;
  flex-wrap: wrap;
}
.wh-form__optiondata--vertical {
  width: 0;
}

.wh-form__subfield {
  display: flex;
  flex-grow: 1;
  align-items: baseline;
}

.wh-form__fieldline > input[type=checkbox],
.wh-form__fieldline > input[type=radio] {
  flex-shrink: 0;
}

.wh-form__fieldline > textarea {
  resize: vertical;
}

::-webkit-input-placeholder {
  opacity: 1;
  color: var(--secondary);
}

::-moz-placeholder {
  opacity: 1;
  color: var(--secondary);
}

:-ms-input-placeholder {
  opacity: 1;
  color: var(--secondary);
}

input:-moz-placeholder {
  opacity: 1;
  color: var(--secondary);
}

.wh-form.wh-styledinput {
  max-width: 800px;
}
.wh-form.wh-styledinput h3 {
  font-size: 21px;
}
.wh-form.wh-styledinput .wh-form__fieldgroup {
  margin-bottom: 15px;
}
.wh-form.wh-styledinput .wh-form__fieldgroup--required > .wh-form__label::after,
.wh-form.wh-styledinput .wh-form__fieldgroup--required .wh-form__label:empty + .wh-form__fields .wh-form__optionlabel::after {
  display: inline-block;
  content: "*";
}
.wh-form.wh-styledinput .wh-form__prefix {
  padding-right: 10px;
  align-self: center;
}
.wh-form.wh-styledinput .wh-form__suffix {
  padding-left: 10px;
  align-self: center;
}
.wh-form.wh-styledinput .wh-form__optionlabel, .wh-form.wh-styledinput .wh-form__subfield > label {
  padding-right: 15px;
}
.wh-form.wh-styledinput .wh-form__label:empty::after {
  display: none;
}
.wh-form.wh-styledinput .wh-form__fieldgroup > .wh-form__label {
  flex: 0 0 var(--label_leftspace);
  max-width: var(--label_leftspace);
  min-width: var(--label_leftspace);
  margin-bottom: 10px;
}
.wh-form.wh-styledinput .wh-form__fieldgroup > .wh-form__fields {
  width: 100%;
}
.wh-form.wh-styledinput .wh-form__fieldgroup--richtext > .wh-form__fields {
  max-width: 100%;
}
.wh-form.wh-styledinput .wh-form__fieldgroup:not(.wh-form__fieldgroup--hidden) {
  flex-direction: column;
}
@media (max-width: var(--onecolumntogglewidth)) {
  .wh-form.wh-styledinput .wh-form__fieldgroup {
    -ms-flex-direction: column;
    -webkit-flex-direction: column;
    flex-direction: column;
    align-items: flex-start;
  }
  .wh-form.wh-styledinput .wh-form__fieldgroup > .wh-form__label {
    padding-bottom: 3px;
  }
  .wh-form.wh-styledinput .wh-form__fieldgroup .wh-form__subfield {
    flex-wrap: wrap;
  }
  .wh-form.wh-styledinput .wh-form__fieldgroup > .wh-form__label, .wh-form.wh-styledinput .wh-form__fieldgroup > .wh-form__fields {
    flex: none;
    max-width: calc(100vw - 40px);
    min-width: 0;
  }
  .wh-form.wh-styledinput .wh-form__fields {
    width: 100%;
  }
}
.wh-form.wh-styledinput select + .wh-form__subfield > label,
.wh-form.wh-styledinput .wh-form__pulldown-styled + .wh-form__subfield > label {
  padding-left: 10px;
}
.wh-form.wh-styledinput input, .wh-form.wh-styledinput select:not(.wh-rtd__toolbarstyle),
.wh-form.wh-styledinput textarea {
  border: 1px solid var(--secondary);
  border-radius: 3px;
  min-height: 44px;
  font-family: inherit;
  font-size: 16px;
  line-height: 20px;
  padding: 11px 15px;
  max-width: 100%;
  color: var(--color_inputtext);
  flex: 1;
  background-color: #fff;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;
}
.wh-form.wh-styledinput input:focus, .wh-form.wh-styledinput input.focus, .wh-form.wh-styledinput select:not(.wh-rtd__toolbarstyle):focus, .wh-form.wh-styledinput select:not(.wh-rtd__toolbarstyle).focus,
.wh-form.wh-styledinput textarea:focus,
.wh-form.wh-styledinput textarea.focus {
  border-color: var(--secondary);
  box-shadow: var(--boxshadow_focus);
}
.wh-form.wh-styledinput input:disabled, .wh-form.wh-styledinput input[data-wh-form-disabled], .wh-form.wh-styledinput select:not(.wh-rtd__toolbarstyle):disabled, .wh-form.wh-styledinput select:not(.wh-rtd__toolbarstyle)[data-wh-form-disabled],
.wh-form.wh-styledinput textarea:disabled,
.wh-form.wh-styledinput textarea[data-wh-form-disabled] {
  color: var(--color_disabled);
  border-color: var(--color_disabled);
  background-color: var(--bgcolor_disabled);
  box-shadow: none;
}
.wh-form.wh-styledinput textarea {
  min-height: 140px;
}
.wh-form.wh-styledinput .wh-form__fieldgroup--error input, .wh-form.wh-styledinput .wh-form__fieldgroup--error select,
.wh-form.wh-styledinput .wh-form__fieldgroup--error textarea,
.wh-form.wh-styledinput .wh-form__fieldgroup--error .wh-form__imgedit,
.wh-form.wh-styledinput .wh-form__fieldgroup--error .wh-form__dateinputgroup,
.wh-form.wh-styledinput .wh-form__fieldgroup--error .wh-form__timeinputgroup {
  border-color: var(--red);
}
.wh-form.wh-styledinput .wh-form__error {
  display: none;
  flex: none;
  width: 100%; /* force error text below item */
  color: var(--red);
  padding: 5px 0 0;
}
.wh-form.wh-styledinput .wh-form__fieldgroup--error .wh-form__error {
  display: block;
}
.wh-form.wh-styledinput .wh-form__fieldgroup--radiogroup .wh-form__fieldline + .wh-form__fieldline,
.wh-form.wh-styledinput .wh-form__fieldgroup--checkboxgroup .wh-form__fieldline + .wh-form__fieldline {
  margin-top: 10px;
}
.wh-form.wh-styledinput .wh-form__optiondata > * {
  padding-left: 15px;
}
.wh-form.wh-styledinput .wh-form__optiondata--horizontal {
  margin-right: 30px;
  width: 100%;
}
.wh-form.wh-styledinput .wh-form__optiondata--horizontal:last-child {
  margin-right: 0;
}
.wh-form.wh-styledinput .wh-form__fieldgroup--horizontal > .wh-form__fields {
  margin-left: -20px;
}
.wh-form.wh-styledinput .wh-form__fieldgroup--horizontal > .wh-form__fields > .wh-form__fieldline {
  padding-left: 20px;
}
.wh-form.wh-styledinput .wh-form__fieldgroup--horizontal .wh-form__fieldline--nowrap {
  margin-top: 10px;
}
.wh-form.wh-styledinput .wh-form__button {
  cursor: pointer;
  height: 44px;
  outline: none;
  -webkit-appearance: none;
  text-decoration: none;
  white-space: nowrap;
  box-shadow: none;
  text-align: center;
  font-size: 16px;
  line-height: 20px;
  padding: 11px 15px;
  background-color: var(--primary);
  color: #fff;
  border: 0 none;
  transition: background-color 0.3s;
  position: relative;
  border-radius: 3px;
}
.wh-form.wh-styledinput .wh-form__buttongroup {
  text-align: right;
  padding: 20px 0;
}
.wh-form.wh-styledinput .wh-form__buttongroup::after {
  content: "";
  display: block;
  clear: both;
}
.wh-form.wh-styledinput .wh-form__buttongroup .wh-form__button {
  min-width: 200px;
  padding-right: 35px;
}
@media (max-width: 500px) {
  .wh-form.wh-styledinput .wh-form__buttongroup .wh-form__button {
    min-width: 0;
  }
}
.wh-form.wh-styledinput .wh-form__buttongroup .wh-form__button::before {
  display: inline-block;
  font-family: "Line Awesome Free";
  font-weight: 900;
  content: "\f061";
  font-size: 22px;
  position: relative;
  left: -5px;
  top: 2px;
  margin-top: -10px;
}
.wh-form.wh-styledinput .wh-form__buttongroup .wh-form__button:hover {
  background-color: var(--primary-hover);
}
.wh-form.wh-styledinput .wh-form__buttongroup .wh-form__button + .wh-form__button {
  margin-left: 15px;
}