.widget-faq {
  position: relative;
}
.widget-faq .card {
  margin-bottom: 10px;
  border: 1px solid var(--border-color) !important;
}
.widget-faq .card.isheader {
  border: 0 !important;
}
.widget-faq .card.isheader .card-header {
  padding: 0;
}
.widget-faq .card.isheader .card-header h4 {
  font-size: 21px;
}
.widget-faq .card:not(.isheader) .card-header:hover, .widget-faq .card:not(.isheader) .card-header:focus {
  background-color: var(--light);
}
.widget-faq .card .card-header {
  background-color: var(--white);
  padding: 0 5px;
  margin-bottom: 0;
  border-bottom: 0;
  border-radius: 0.375rem;
}
.widget-faq .card .card-header .btn-link {
  color: var(--dark);
  display: flex;
  width: 100%;
  text-align: left;
  padding: 0.5rem;
  text-decoration: none;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-weight: 400;
  font-size: 18px;
}
.widget-faq .card .card-header .btn-link:hover {
  text-decoration: underline;
}
.widget-faq .card .card-header .btn:after {
  font-family: "Line Awesome Free";
  font-weight: 900;
  content: "\f068";
  float: right;
  color: var(--dark);
  font-size: 20px;
}
.widget-faq .card .card-header .btn.collapsed:after {
  content: "\f067";
}