.pagetype--iscountry .ewr_headerimage {
  max-height: 400px;
  overflow: hidden;
}
.pagetype--iscountry .ewr__trips__filter .label_countrycode, .pagetype--iscountry .ewr__trips__filter .filter_countrycode,
.pagetype--iscountry .ewr__searchmap__filter .label_countrycode,
.pagetype--iscountry .ewr__searchmap__filter .filter_countrycode {
  display: none;
}
.pagetype--iscountry .countrypage__intro {
  padding: 50px 0;
}
@media (max-width: 991.8px) {
  .pagetype--iscountry .countrypage__intro {
    padding: 25px 0;
  }
  .pagetype--iscountry .countrypage__intro .row {
    margin: 0;
  }
  .pagetype--iscountry .countrypage__intro .row .col-lg-8 {
    margin-top: -75px;
    background-color: var(--light);
    padding: 20px;
  }
}
.pagetype--iscountry .countrypage__openstreetmap__image img {
  border-radius: 100rem;
  border: 20px solid var(--white);
}
@media (max-width: 991.8px) {
  .pagetype--iscountry .countrypage__openstreetmap__image {
    display: none;
  }
}
@media (min-width: 992px) {
  .pagetype--iscountry .countrypage__openstreetmap {
    margin-top: -80px;
  }
}
.pagetype--iscountry .countrypage__openstreetmap .btn {
  margin-top: 20px;
}
@media (min-width: 992px) {
  .pagetype--iscountry .countrypage__openstreetmap .btn {
    margin-top: -40px;
  }
}
@media (max-width: 565px) {
  .pagetype--iscountry .countrypage__openstreetmap .btn {
    width: 47% !important;
  }
}
.pagetype--iscountry .countrypage__longdescription {
  padding: 50px 0;
}
.pagetype--iscountry .countrypage__longdescription .countrypage__highlights {
  margin-right: calc(-0.5 * var(--bs-gutter-x));
  margin-left: calc(-0.5 * var(--bs-gutter-x));
}
.pagetype--iscountry .countrypage__longdescription .ewr__tipimage {
  position: relative;
  margin-right: calc(-0.5 * var(--bs-gutter-x));
  margin-left: calc(-0.5 * var(--bs-gutter-x));
}
.pagetype--iscountry .countrypage__longdescription .ewr__tipimage .handwritten {
  position: absolute;
  top: 90px;
  left: 37px;
  color: var(--white);
  font-size: 100px;
  text-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}
.pagetype--iscountry .countrypage__longdescription .ewr__tipimage__description {
  background-color: #d86d17;
  padding: 25px;
}
.pagetype--iscountry .countrypage__longdescription .ewr__tipimage__description h2,
.pagetype--iscountry .countrypage__longdescription .ewr__tipimage__description h3,
.pagetype--iscountry .countrypage__longdescription .ewr__tipimage__description p {
  color: #fff;
}
@media (max-width: 991.8px) {
  .pagetype--iscountry .countrypage__trips__result .ewr__trips__filter {
    display: none;
  }
}
.pagetype--iscountry .countrypage__cta__row {
  padding: 100px 0;
  background-color: var(--primary-light);
}
.pagetype--iscountry .countrypage__cta__row h4 {
  font-size: 2rem;
}