.twocolimgrightbg .bg-overlay.bg-darkblue {
  background-color: var(--darkblue);
}
.twocolimgrightbg .bg-overlay.bg-darkblue > * {
  color: var(--white);
}
.twocolimgrightbg .bg-overlay.bg-blue {
  background-color: var(--blue);
}
.twocolimgrightbg .bg-overlay.bg-blue > * {
  color: var(--white);
}
.twocolimgrightbg .bg-overlay.bg-darkgreen {
  background-color: var(--darkgreen);
}
.twocolimgrightbg .bg-overlay.bg-darkgreen > * {
  color: var(--white);
}
.twocolimgrightbg .bg-overlay.bg-green {
  background-color: var(--green);
}
.twocolimgrightbg .bg-overlay.bg-green > * {
  color: var(--white);
}
.twocolimgrightbg .bg-overlay.bg-brown {
  background-color: var(--brown);
}
.twocolimgrightbg .bg-overlay.bg-brown > * {
  color: var(--white);
}
.twocolimgrightbg .bg-overlay.bg-orange {
  background-color: var(--orange);
}
.twocolimgrightbg .bg-overlay.bg-orange > * {
  color: var(--white);
}
.twocolimgrightbg .bg-overlay.bg-red {
  background-color: var(--red);
}
.twocolimgrightbg .bg-overlay.bg-red > * {
  color: var(--white);
}
.twocolimgrightbg .bg-overlay.bg-lightgreen {
  background-color: var(--lightgreen);
}
.twocolimgrightbg .bg-overlay.bg-lightgreen > * {
  color: var(--darkgreen);
}
.twocolimgrightbg .bg-overlay.bg-lightbrown {
  background-color: var(--lightbrown);
}
.twocolimgrightbg .bg-overlay.bg-lightbrown > * {
  color: var(--brown);
}
.twocolimgrightbg .bg-overlay.bg-lightorange {
  background-color: var(--lightorange);
}
.twocolimgrightbg .bg-overlay.bg-lightorange > * {
  color: var(--orange);
}
.twocolimgrightbg .bg-overlay.bg-lightblue {
  background-color: var(--lightblue);
}
.twocolimgrightbg .bg-overlay.bg-lightblue > * {
  color: var(--darkblue);
}
.twocolimgrightbg .bg-overlay.bg-lightred {
  background-color: var(--lightred);
}
.twocolimgrightbg .bg-overlay.bg-lightred > * {
  color: var(--red);
}
.twocolimgrightbg .handwritten {
  position: absolute;
  top: 20px;
  right: 20px;
  color: var(--white);
  font-family: var(--theme-handwriting-font);
  font-size: 62px;
  text-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  line-height: 56px;
  text-align: right;
  max-width: 300px;
}
.twocolimgrightbg .handwritten.topright {
  top: 20px;
  right: 20px;
  text-align: right;
}
.twocolimgrightbg .handwritten.bottomright {
  top: inherit;
  bottom: 20px;
  right: 20px;
  text-align: right;
}
.twocolimgrightbg .handwritten.topleft {
  top: 20px;
  left: 20px;
  text-align: left;
}
.twocolimgrightbg .handwritten.bottomleft {
  top: inherit;
  bottom: 20px;
  left: 20px;
  text-align: left;
}
@media (max-width: 565px) {
  .twocolimgrightbg .handwritten {
    font-size: 42px;
    line-height: 42px;
    max-width: 175px;
  }
}