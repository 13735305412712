.ewr__popular__trips {
  background: url(../../web/img/ewr-popular-trips.jpg) no-repeat 0 0/cover;
  position: relative;
  overflow: hidden;
}
.ewr__popular__trips .splide__fade {
  position: absolute;
  z-index: 1;
  top: 0%;
  bottom: 0%;
  left: 0%;
  right: 0%;
  height: 100%;
  pointer-events: none;
}
.ewr__popular__trips .splide__fade-left {
  background-image: linear-gradient(to right, #e3ece0, rgba(227, 236, 224, 0) 15%);
}
.ewr__popular__trips .splide__fade-right {
  background-image: linear-gradient(to left, #e3ece0, rgba(227, 236, 224, 0) 15%);
}
@media (max-width: 767px) {
  .ewr__popular__trips .container-xl {
    padding-left: 0;
    padding-right: 0;
  }
}
.ewr__popular__trips .handwritten_title {
  color: var(--primary);
}
.ewr__popular__trips .splide .splide__arrows .splide__arrow {
  background-color: var(--white);
  opacity: 1;
  width: 100px;
  border-radius: 30px;
  height: 50px;
  z-index: 2;
  position: absolute;
}
.ewr__popular__trips .splide .splide__arrows .splide__arrow .las {
  color: var(--primary);
  font-size: 21px;
  position: relative;
  transition: transform 0.3s ease;
  transform: translateX(0);
}
.ewr__popular__trips .splide .splide__arrows .splide__arrow .las.la-arrow-left {
  left: 20px;
}
.ewr__popular__trips .splide .splide__arrows .splide__arrow .las.la-arrow-right {
  right: 20px;
}
.ewr__popular__trips .splide .splide__arrows .splide__arrow--prev {
  left: -50px;
}
.ewr__popular__trips .splide .splide__arrows .splide__arrow--prev:hover .la-arrow-left {
  animation: slideLeft 0.8s ease-in-out infinite;
}
.ewr__popular__trips .splide .splide__arrows .splide__arrow--next {
  right: -50px;
}
.ewr__popular__trips .splide .splide__arrows .splide__arrow--next:hover .la-arrow-right {
  animation: slideRight 0.8s ease-in-out infinite;
}
.ewr__popular__trips .splide .splide__track .splide__list .splide__slide .ewr__popular__trips__item {
  text-align: center;
}
.ewr__popular__trips .splide .splide__track .splide__list .splide__slide .ewr__popular__trips__item a {
  text-decoration: none;
}
.ewr__popular__trips .splide .splide__track .splide__list .splide__slide .ewr__popular__trips__item__img {
  border-radius: 100%;
  overflow: hidden;
  width: 350px;
  height: 350px;
  margin: 0 auto;
  margin-bottom: 20px;
  border: 15px solid var(--white);
}
@media (min-width: 576px) and (max-width: 1199.98px) {
  .ewr__popular__trips .splide .splide__track .splide__list .splide__slide .ewr__popular__trips__item__img {
    width: 300px;
    height: 300px;
  }
}
@media (max-width: 576.98px) {
  .ewr__popular__trips .splide .splide__track .splide__list .splide__slide .ewr__popular__trips__item__img {
    width: 230px;
    height: 230px;
  }
}
@media (max-width: 420px) {
  .ewr__popular__trips .splide .splide__track .splide__list .splide__slide .ewr__popular__trips__item__img {
    width: 200px;
    height: 200px;
  }
}
.ewr__popular__trips .splide .splide__track .splide__list .splide__slide .ewr__popular__trips__item__img img {
  object-fit: cover;
  height: 100%;
  margin-bottom: 20px;
}
.ewr__popular__trips .splide .splide__track .splide__list .splide__slide .ewr__popular__trips__item__title {
  display: block;
  font-size: 28px;
  margin-bottom: 5px;
  font-family: var(--theme-heading-font);
  color: var(--font-color);
}
.ewr__popular__trips .splide .splide__track .splide__list .splide__slide .ewr__popular__trips__item__meta {
  display: block;
  font-size: 21px;
  margin-bottom: 10px;
  color: var(--font-color);
}
.ewr__popular__trips .splide .splide__track .splide__list .splide__slide .ewr__popular__trips__item__countries {
  display: block;
}

html.sitetheme--hch .ewr__popular__trips {
  background: url(../../web/img/hch-popular-trips.jpg) no-repeat 0 0/cover;
}