@media (max-width: 565px) {
  main > :not(#masthead) .btn {
    width: 100%;
  }
}

.btn {
  font-family: var(--theme-font);
  white-space: normal;
  font-weight: 700;
  padding: 0.5rem 1rem;
  border-radius: 4px;
}
.btn:active, .btn.active, .btn:focus .btn:hover {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.btn-primary {
  background-color: var(--primary);
  border-color: var(--primary);
  color: #fff;
}
.btn-primary:active, .btn-primary.active, .btn-primary:focus, .btn-primary:hover {
  background-color: var(--primary-hover);
  border-color: var(--primary-hover);
  color: #fff;
}
.btn-secondary {
  background-color: var(--secondary);
  border-color: var(--secondary);
  color: #fff;
}
.btn-secondary:active, .btn-secondary.active, .btn-secondary:focus, .btn-secondary:hover {
  background-color: var(--secondary-hover);
  border-color: var(--secondary-hover);
  color: #fff;
}
.btn-light {
  background-color: var(--secondary-light);
  border-color: var(--secondary-light);
}
.btn-light:active, .btn-light.active, .btn-light:focus, .btn-light:hover {
  background-color: var(--secondary-hover);
  border-color: var(--secondary-hover);
  color: #fff;
}
.btn i {
  font-size: 21px;
  position: relative;
  top: 2px;
}
.btn .las.la-filter {
  background: url('data:image/svg+xml,<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 350.7 350.7" xml:space="preserve"><g><path fill="%23ffffff" d="M145.1,255.6c-2.9,0-4.3,0.6-5.3,3.6c-8.5,27.3-36,46.3-63.7,44.2c-31.1-2.3-55.3-23.9-60-53.6c-5.3-33.2,15.8-64.7,48.3-72.4c32.6-7.7,65.5,11,75.5,43.1c0.9,2.8,2.3,3.2,4.8,3.2c57.6,0,115.3,0,172.9,0c10.3,0,17.3,7,17,16.7c-0.3,8.9-7.3,15.2-17,15.3C303.4,255.7,173.8,255.7,145.1,255.6z M79.1,207.7c-17.6,0.1-31.9,14.3-31.9,31.9c0,17.5,14.4,31.9,31.9,31.9c17.7,0,32.2-14.6,32.1-32.1C111,221.7,96.7,207.7,79.1,207.7z"/><path fill="%23ffffff" d="M205.3,95.8c2.9,0,4.1-0.9,4.9-3.6c8.6-27.4,36.2-46.5,64-44.3c31,2.5,55.1,24.1,59.7,53.6c5.2,33.3-15.6,64.6-48.2,72.4c-32.5,7.8-65.6-11-75.6-43c-0.9-2.8-2.2-3.3-4.7-3.3c-57.6,0.1-115.3,0.1-172.9,0c-10.2,0-17.2-6.8-17-16.3c0.1-9.1,7.1-15.6,17-15.7C46.6,95.7,176.4,95.7,205.3,95.8z M271,143.7c17.6-0.1,31.9-14.4,31.8-31.9c-0.1-17.7-14.6-32-32.3-31.8c-17.5,0.2-31.8,14.8-31.6,32.2C239.1,129.7,253.4,143.7,271,143.7z"/></g></svg>');
  width: 18px;
  height: 18px;
  background-size: 110%;
  background-position: -1px 0px;
  margin-right: 5px;
}
.btn .las.la-filter:before {
  display: none;
}

@keyframes slideRight {
  0%, 100% {
    transform: translate(0, 0);
  }
  50% {
    transform: translate(5px, 0);
  }
}
@keyframes slideLeft {
  0%, 100% {
    transform: translate(0, 0);
  }
  50% {
    transform: translate(-5px, 0);
  }
}