.ewr__certainties .text-primary {
  color: var(--primary) !important;
}
.ewr__certainties h2 {
  display: block;
  max-width: 460px;
  margin: 0 auto;
  margin-top: 5px;
}
.ewr__certainties .ewr__certainty {
  text-align: center;
}
.ewr__certainties .ewr__certainty img {
  margin-bottom: 20px;
}
@media (max-width: 565px) {
  .ewr__certainties .ewr__certainty img {
    margin-bottom: 10px;
  }
}
.ewr__certainties .ewr__certainty__title {
  display: block;
  font-family: var(--theme-heading-font);
  font-size: 21px;
}
@media (max-width: 565px) {
  .ewr__certainties .ewr__certainty__title {
    font-size: 18px;
  }
}
.ewr__certainties .ewr__certainty__desc {
  font-size: 18px;
}
@media (max-width: 565px) {
  .ewr__certainties .ewr__certainty__desc {
    font-size: 16px;
  }
}