.pagetype--blog main,
.pagetype--blogitem main,
.pagetype--blogtagpage main {
  background-color: var(--light);
}
.pagetype--blog .ewr_headerimage,
.pagetype--blogitem .ewr_headerimage,
.pagetype--blogtagpage .ewr_headerimage {
  max-height: 400px;
  overflow: hidden;
}
.pagetype--blog .blog__result__item h3 a,
.pagetype--blogitem .blog__result__item h3 a,
.pagetype--blogtagpage .blog__result__item h3 a {
  text-decoration: none;
  color: var(--dark);
}
.pagetype--blog .related__blog__item .labels,
.pagetype--blogitem .related__blog__item .labels,
.pagetype--blogtagpage .related__blog__item .labels {
  position: absolute;
  bottom: -8px;
  left: 17px;
  background: var(--light);
  padding: 5px 6px 0 10px;
}
.pagetype--blog .related__blog__item h4 a,
.pagetype--blogitem .related__blog__item h4 a,
.pagetype--blogtagpage .related__blog__item h4 a {
  text-decoration: none;
  color: var(--dark);
}
.pagetype--blog .labels .labels__inner,
.pagetype--blogitem .labels .labels__inner,
.pagetype--blogtagpage .labels .labels__inner {
  border: 1px solid var(--border-color);
  display: inline-block;
  border-radius: 0.25rem;
  padding: 2px 6px;
  margin: 5px 5px 1px 0;
  font-size: 13px;
  line-height: 19px;
}
.pagetype--blog .labels .labels__inner a,
.pagetype--blogitem .labels .labels__inner a,
.pagetype--blogtagpage .labels .labels__inner a {
  color: var(--dark);
  text-decoration: none;
}
@media (max-width: 991.98px) {
  .pagetype--blog .labels,
  .pagetype--blogitem .labels,
  .pagetype--blogtagpage .labels {
    padding-top: 10px;
    margin-top: 10px;
    border-top: 1px solid var(--border-color-light);
  }
}

.pagetype--blogitem main {
  background-color: var(--white);
}