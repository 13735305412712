.site__footer {
  position: relative;
  padding: 0 0 50px;
}
.site__footer .ewr_newsletter__subscription__title {
  font-family: var(--theme-heading-font);
  font-size: 32px;
  line-height: 1.2;
  margin-bottom: 25px;
  display: block;
}
.site__footer .ewr_newsletter__subscription form {
  background-color: rgba(255, 255, 255, 0.8);
  padding: 30px;
}
.site__footer .ewr_newsletter__subscription form .form-label {
  color: var(--font-color);
}
.site__footer .ewr_newsletter__subscription form .form-control,
.site__footer .ewr_newsletter__subscription form .form-select {
  border: 0;
}
.site__footer .ewr_social__icons {
  background-color: rgba(255, 255, 255, 0.8);
  padding: 47px 15px 40px 15px;
  position: relative;
  min-height: 137px;
}
.site__footer .ewr_social__icons__icon .ewr_social__icon__link {
  display: inline-block;
}
.site__footer .ewr_social__icons__icon .ewr_social__icon__link a {
  display: inline-block;
  width: 44px;
  height: 44px;
  background-color: var(--white);
  border-radius: 25rem;
  float: left;
  margin-right: 10px;
  text-align: center;
  line-height: 54px;
}
.site__footer .ewr_social__icons__icon .ewr_social__icon__link a i {
  color: var(--font-color);
  font-size: 28px;
}
.site__footer .ewr_social__icons .circle {
  border: 15px solid var(--secondary);
  background-color: var(--primary);
  position: absolute;
  display: flex;
  right: 0;
  width: 140px;
  height: 140px;
  text-align: center;
  border-radius: 100%;
  top: -100px;
  right: -35px;
  transform: rotate(9deg);
  padding: 25px 4px;
}
@media (max-width: 1199.98px) {
  .site__footer .ewr_social__icons .circle {
    top: -53px;
    right: -18px;
  }
}
@media (max-width: 991.8px) {
  .site__footer .ewr_social__icons .circle {
    top: -42px;
    right: 0;
    width: 110px;
    height: 110px;
    padding: 14px 4px;
  }
}
.site__footer .ewr_social__icons .circle .ewr_social__icons__title {
  font-family: var(--theme-handwriting-font);
  font-size: 39px;
  display: flex;
  align-items: center;
}
@media (max-width: 991.8px) {
  .site__footer .ewr_social__icons .circle .ewr_social__icons__title {
    font-size: 36px;
    line-height: 28px;
  }
}
.site__footer h5 {
  font-family: var(--theme-heading-font);
  font-size: 21px;
  margin-bottom: 10px;
}
.site__footer a,
.site__footer li,
.site__footer p {
  font-size: 16px;
  line-height: 24px;
}
.site__footer a {
  text-decoration: none;
  color: var(--primary);
}
.site__footer a:hover {
  text-decoration: underline;
}
.site__footer ul {
  display: block;
  margin: 0 0 20px 0;
  padding: 0;
  list-style: none;
}
.site__footer ul li a {
  color: var(--font-color);
  line-height: 32px;
}
@media (max-width: 575.98px) {
  .site__footer .footer__widgets {
    padding-left: 0;
    padding-right: 0;
  }
  .site__footer .footer__widgets .footer__widget:nth-child(4) {
    box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.1);
  }
}
.site__footer .footer__widgets .col-collapse .collapse {
  display: inherit;
}
@media (max-width: 767.98px) {
  .site__footer .footer__widgets .col-collapse {
    border-bottom: 1px solid var(--border-color-light);
  }
  .site__footer .footer__widgets .col-collapse .collapse {
    display: none;
  }
  .site__footer .footer__widgets .col-collapse .collapse.show {
    display: block;
  }
  .site__footer .footer__widgets .col-collapse .title {
    font-family: var(--theme-heading-font);
    font-size: 18px;
  }
  .site__footer .footer__widgets .col-collapse .title:hover {
    cursor: pointer;
  }
  .site__footer .footer__widgets .col-collapse .title .la-angle-up,
  .site__footer .footer__widgets .col-collapse .title[aria-expanded=true] .la-angle-down {
    display: none;
  }
  .site__footer .footer__widgets .col-collapse .title {
    margin-bottom: 10px;
  }
  .site__footer .footer__widgets .col-collapse .title[aria-expanded=true] .la-angle-up {
    display: block;
  }
  .site__footer .footer__widgets .row {
    margin-right: 0;
    margin-left: 0;
  }
}
.site__footer .footer__copyright p,
.site__footer .footer__bottom p {
  margin-bottom: 0;
}
@media (min-width: 566.98px) {
  .site__footer .footer__copyright {
    padding-top: 1.5rem;
    border-top: 1px solid var(--border-color-light);
  }
}
.site__footer .footer__logos a {
  display: inline-block;
}
.site__footer .footer__logos a:not(:last-child) {
  margin-right: 20px;
}
.site__footer .footer__logos a img {
  max-height: 40px;
}