.ewr__featured_categories .col a {
  color: #fff;
  display: block;
  text-decoration: none;
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
}
.ewr__featured_categories .col a .category {
  position: relative;
  top: 0;
  margin: 0 0 20px;
  overflow: hidden;
}
.ewr__featured_categories .col a .category .img-hover-zoom {
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.ewr__featured_categories .col a .category .img-hover-zoom img {
  object-fit: contain;
  width: 100%;
  height: auto;
  transition: transform 0.3s ease;
}
.ewr__featured_categories .col a .category .img-hover-zoom img:hover, .ewr__featured_categories .col a .category .img-hover-zoom img:focus {
  transform: scale(1.05);
}
.ewr__featured_categories .col a .category span {
  position: absolute;
  bottom: 5px;
  right: 10px;
  padding: 3px 9pt;
  background-color: var(--primary);
  border-radius: 0.25rem;
}