.mapbox__map {
  height: 75vh;
  width: 100%;
}
@media (max-width: 565px) {
  .mapbox__map {
    height: 50vh;
  }
}
.mapbox__map .mapboxgl-popup {
  max-width: 275px !important;
}

.mapboxgl-popup-content {
  padding: 0;
  border-radius: 6px;
  max-width: 275px;
}
.mapboxgl-popup-content a {
  text-decoration: none;
  color: var(--font-color);
}

.mapboxgl-popup-close-button {
  right: -25px;
  top: -21px;
  font-size: 24px;
}

.mapbox__popup__body {
  padding: 15px;
}
.mapbox__popup__body .trips__result__item__tags {
  display: block;
}
.mapbox__popup__body .trips__result__item__tags .trips__result__item__tag {
  padding: 1px 4px;
  margin: 0 5px 1px 0;
  font-size: 11px;
}
.mapbox__popup__body .trips__result__item__tags .trips__result__item__tag.tag_Hond, .mapbox__popup__body .trips__result__item__tags .trips__result__item__tag.tag_Hund, .mapbox__popup__body .trips__result__item__tags .trips__result__item__tag.tag_Dog {
  height: 23px;
  position: relative;
  top: 1px;
}
.mapbox__popup__title {
  color: inherit;
  font-size: 18px;
  font-weight: 700;
  text-decoration: none;
  display: block;
}
.mapbox__popup__images img {
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}
.mapbox__popup__tripmeta {
  display: block;
}
.mapbox__popup__tripmeta span:not(:last-child) {
  margin-right: 5px;
}
.mapbox__popup__tripmeta span:not(:last-child):after {
  content: "-";
  margin-left: 5px;
}
.mapbox__popup__btn .btn {
  margin-top: 10px;
  padding: 0.2rem 0.3rem;
}
.mapbox__popup__btn .btn i {
  font-size: 18px;
}