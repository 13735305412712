.ewr__themes {
  padding: 40px 0;
}
@media (max-width: 991.98px) {
  .ewr__themes {
    padding: 40px 0 0 0;
  }
}
.ewr__themes__block .card-body {
  position: relative;
}
.ewr__themes__block .card-body h3 {
  font-family: var(--theme-handwriting-font);
  font-size: 60px;
  color: var(--white);
  position: absolute;
  top: -72px;
  display: block;
  width: 100%;
  left: 0px;
  padding-left: 25px;
  z-index: 2;
}
@media (max-width: 565px) {
  .ewr__themes__block .card-body h3 {
    font-size: 42px;
    top: -50px;
  }
}
.ewr__themes__block .card-body h3:before {
  content: "";
  position: absolute;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.7));
  width: 100%;
  bottom: 0;
  left: 0;
  height: 100px;
  z-index: -1;
}
.ewr__themes__block .card-body ul {
  margin-bottom: 0;
  columns: 2;
  -webkit-columns: 2;
  -moz-columns: 2;
}
@media (max-width: 565px) {
  .ewr__themes__block .card-body ul {
    columns: 1;
    -webkit-columns: 1;
    -moz-columns: 1;
  }
}
.ewr__themes__block .card-body ul li a {
  text-decoration: none;
  color: var(--font-color);
  font-size: 16px;
  display: table-cell;
}
.ewr__themes__block .card-body ul li a:hover {
  text-decoration: underline;
}
.ewr__themes__block .card-body ul li:before {
  font-size: 16px;
  color: var(--primary);
}
.ewr__themes__block .card-footer {
  padding: 0 1rem 20px 20px;
}