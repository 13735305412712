@media (min-width: 992px) {
  .twocolimgright .with-thumb p:last-child {
    padding-right: 100px;
  }
}
.twocolimgright .thumb {
  border-radius: 150px;
  border: 16px solid #fff;
  position: absolute;
  z-index: 1;
}
@media (min-width: 1200px) {
  .twocolimgright .thumb {
    left: -96px;
    bottom: -115px;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .twocolimgright .thumb {
    left: -96px;
    bottom: -115px;
  }
}
@media (max-width: 991.98px) {
  .twocolimgright .thumb {
    left: inherit;
    right: 7px;
    bottom: -40px;
    width: 125px;
    height: 125px;
    border: 12px solid #fff;
  }
}
.twocolimgright .handwritten {
  position: absolute;
  bottom: 20px;
  right: 20px;
  color: var(--white);
  font-family: var(--theme-handwriting-font);
  font-size: 62px;
  text-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  line-height: 56px;
  text-align: right;
  max-width: 300px;
}
.twocolimgright .handwritten.topright {
  top: 20px;
  right: 40px;
  text-align: right;
}
.twocolimgright .handwritten.bottomright {
  top: inherit;
  bottom: 20px;
  right: 40px;
  text-align: right;
}
.twocolimgright .handwritten.topleft {
  top: 20px;
  left: 40px;
  text-align: left;
}
.twocolimgright .handwritten.bottomleft {
  top: inherit;
  bottom: 20px;
  left: 40px;
  text-align: left;
}
@media (max-width: 565px) {
  .twocolimgright .handwritten {
    font-size: 42px;
    line-height: 42px;
    max-width: 175px;
  }
}