.ewr__travel__inspiriation {
  overflow: hidden;
  position: relative;
}
.ewr__travel__inspiriation .handwritten_title {
  color: var(--primary);
}
.ewr__travel__inspiriation h2 {
  color: #fff;
}
.ewr__travel__inspiriation .splide__arrows .splide__arrow {
  background-color: var(--secondary);
  opacity: 1;
  width: 100px;
  border-radius: 30px;
  height: 50px;
  z-index: 2;
  position: absolute;
}
.ewr__travel__inspiriation .splide__arrows .splide__arrow .las {
  color: var(--white);
  font-size: 21px;
  position: relative;
  transition: transform 0.3s ease;
  transform: translateX(0);
}
.ewr__travel__inspiriation .splide__arrows .splide__arrow .las.la-arrow-left {
  left: 20px;
}
.ewr__travel__inspiriation .splide__arrows .splide__arrow .las.la-arrow-right {
  right: 20px;
}
.ewr__travel__inspiriation .splide__arrows .splide__arrow--prev {
  left: -50px;
}
.ewr__travel__inspiriation .splide__arrows .splide__arrow--prev:hover .la-arrow-left {
  animation: slideLeft 0.8s ease-in-out infinite;
}
.ewr__travel__inspiriation .splide__arrows .splide__arrow--next {
  right: -50px;
}
.ewr__travel__inspiriation .splide__arrows .splide__arrow--next:hover .la-arrow-right {
  animation: slideRight 0.8s ease-in-out infinite;
}
.ewr__travel__inspiriation .splide__fade {
  position: absolute;
  z-index: 1;
  top: 0%;
  bottom: 0%;
  left: 0%;
  right: 0%;
  height: 100%;
  pointer-events: none;
}
.ewr__travel__inspiriation .splide__fade-left {
  background-image: linear-gradient(to right, #064152, rgba(6, 65, 82, 0) 10%);
}
.ewr__travel__inspiriation .splide__fade-right {
  background-image: linear-gradient(to left, #064152, rgba(6, 65, 82, 0) 10%);
}
.ewr__travel__inspiriation .splide__list {
  padding: 50px 0 !important;
}
.ewr__travel__inspiriation .splide__list .splide__slide:nth-child(odd) {
  transform: rotate(-3deg);
}
.ewr__travel__inspiriation .splide__list .splide__slide:nth-child(even) {
  transform: rotate(3deg);
  margin-top: -20px;
}
.ewr__travel__inspiriation .splide__list .splide__slide .trips__theme {
  background-color: #fff;
  padding: 25px;
  overflow: hidden;
}
.ewr__travel__inspiriation .splide__list .splide__slide .trips__theme a {
  text-decoration: none;
}
.ewr__travel__inspiriation .splide__list .splide__slide .trips__theme__img {
  margin-bottom: 20px;
}
.ewr__travel__inspiriation .splide__list .splide__slide .trips__theme__title {
  font-family: var(--theme-heading-font);
  font-size: 24px;
  margin-bottom: 10px;
  color: var(--dark);
  display: block;
}
.ewr__travel__inspiriation .splide__list .splide__slide .trips__theme__descr {
  color: var(--dark);
}
.ewr__travel__inspiriation .splide__list .splide__slide .trips__theme__link {
  color: var(--primary);
  float: right;
  margin-top: 10px;
}