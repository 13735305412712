.twocolimgfull .bg {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}
.twocolimgfull .bg .bg-overlay.bg-darkblue {
  background-color: var(--darkblue-a);
}
.twocolimgfull .bg .bg-overlay.bg-blue {
  background-color: var(--blue-a);
}
.twocolimgfull .bg .bg-overlay.bg-darkgreen {
  background-color: var(--darkgreen-a);
}
.twocolimgfull .bg .bg-overlay.bg-green {
  background-color: var(--green-a);
}
.twocolimgfull .bg .bg-overlay.bg-brown {
  background-color: var(--brown-a);
}
.twocolimgfull .bg .bg-overlay.bg-orange {
  background-color: var(--orange-a);
}
.twocolimgfull .bg .bg-overlay.bg-red {
  background-color: var(--red-a);
}
.twocolimgfull .bg .bg-overlay > * {
  color: var(--white);
}