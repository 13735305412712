.trips__result__item {
  position: relative;
}
.trips__result__item .carousel .carousel-control-prev {
  width: inherit;
  opacity: 1;
}
.trips__result__item .carousel .carousel-control-prev .carousel-control-prev-icon {
  background-color: var(--secondary);
  background-image: url('data:image/svg+xml,<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 512 512" xml:space="preserve"><g><path fill="%23ffffff" d="M135.6,240.9c1.4,0,2.9,0,4.3,0c90.8,0,181.6,0,272.5,0c2.5,0,5,0,7.4,0.6c7.4,2,12.3,9.4,11.5,16.8c-1,8.2-7.1,13.9-15.2,14.3c-1.5,0.1-3,0-4.5,0c-90.6,0-181.1,0-271.7,0c-1.4,0-2.9,0-4.3,0c-0.4,0.5-0.8,1.1-1.1,1.6c1.2,0.7,2.7,1.1,3.6,2c26.6,26.5,53.1,53.1,79.7,79.6c4.8,4.8,7,10.2,5.1,16.9c-3.1,10.8-16.1,15-25.1,8.1c-1.2-0.9-2.2-2-3.3-3.1c-36-36-72-72-108-108c-8.8-8.8-8.8-17.4,0-26.3c36.2-36.3,72.5-72.5,108.7-108.8c4.8-4.8,10.1-7.2,16.9-5.4c11.2,3.1,15.5,16.6,8.1,25.5c-0.9,1.1-1.9,2-2.8,3c-26.5,26.5-52.9,52.9-79.4,79.4c-0.9,0.9-2.2,1.5-3.4,2.2C135,239.9,135.3,240.4,135.6,240.9z"/></g></svg>');
  background-size: 81%;
  background-repeat: no-repeat;
  background-position: 3px -1px;
  border-bottom-right-radius: 20px;
  border-top-right-radius: 20px;
  width: 2.5rem;
}
.trips__result__item .carousel .carousel-control-next {
  width: inherit;
  opacity: 1;
}
.trips__result__item .carousel .carousel-control-next .carousel-control-next-icon {
  background-color: var(--secondary);
  background-image: url('data:image/svg+xml,<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 512 512" xml:space="preserve"><g><path fill="%23ffffff" d="M375.7,272.3c-1.4,0-2.9,0-4.3,0c-90.8,0-181.6,0-272.5,0c-2.5,0-5,0-7.4-0.6c-7.4-2-12.3-9.4-11.5-16.8c1-8.2,7.1-13.9,15.2-14.3c1.5-0.1,3,0,4.5,0c90.6,0,181.1,0,271.7,0c1.4,0,2.9,0,4.3,0c0.4-0.5,0.8-1.1,1.1-1.6c-1.2-0.7-2.7-1.1-3.6-2c-26.6-26.5-53.1-53.1-79.7-79.6c-4.8-4.8-7-10.2-5.1-16.9c3.1-10.8,16.1-15,25.1-8.1c1.2,0.9,2.2,2,3.3,3.1c36,36,72,72,108,108c8.8,8.8,8.8,17.4,0,26.3C388.5,305.8,352.2,342,316,378.3c-4.8,4.8-10.1,7.2-16.9,5.4c-11.2-3.1-15.5-16.6-8.1-25.5c0.9-1.1,1.9-2,2.8-3c26.5-26.5,52.9-52.9,79.4-79.4c0.9-0.9,2.2-1.5,3.4-2.2C376.4,273.2,376,272.7,375.7,272.3z"/></g></svg>');
  background-size: 81%;
  background-repeat: no-repeat;
  background-position: 3px -1px;
  border-bottom-left-radius: 20px;
  border-top-left-radius: 20px;
  width: 2.5rem;
}
.trips__result__item h3 a {
  color: var(--secondary);
  text-decoration: none;
}
.trips__result__item h3 a:hover {
  text-decoration: underline;
}
.trips__result__item__tags span {
  border: 1px solid var(--border-color);
  display: inline-block;
  border-radius: 0.25rem;
  padding: 2px 6px;
  margin: 0 5px 1px 0;
  font-size: 13px;
  line-height: 19px;
}
@media (max-width: 991.98px) {
  .trips__result__item__tags {
    padding-top: 10px;
    margin-top: 10px;
    border-top: 1px solid var(--border-color-light);
  }
}
.trips__result__item__meta {
  margin-bottom: 10px;
}
.trips__result__item .checkmarks {
  padding: 0;
}
.trips__result__item .checkmarks li {
  font-size: 16px;
  line-height: 22px;
}
.trips__result__item .checkmarks li:before {
  width: 26px;
  background-size: 17px;
  background-position: 6px 3px;
}
.trips__result__item__price {
  width: 70%;
  margin-top: 15px;
  background-color: var(--primary-light);
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@media (max-width: 1399.98px) {
  .trips__result__item__price {
    width: 100%;
  }
}
.trips__result__item__price .trip__lowestprice {
  font-family: var(--theme-heading-font);
  font-size: 32px;
}

.country__badge {
  display: inline-block;
  width: 26px;
  height: 26px;
}
.country__badge:not(:last-child) {
  margin-right: 10px;
}
.country__badge__belgie {
  background: url(../../web/img/be.png) no-repeat 0 0/cover;
}
.country__badge__duitsland {
  background: url(../../web/img/de.png) no-repeat 0 0/cover;
}
.country__badge__frankrijk {
  background: url(../../web/img/fr.png) no-repeat 0 0/cover;
}
.country__badge__griekenland {
  background: url(../../web/img/gr.png) no-repeat 0 0/cover;
}
.country__badge__groot-brittannie {
  background: url(../../web/img/gb.png) no-repeat 0 0/cover;
}
.country__badge__ierland {
  background: url(../../web/img/ie.png) no-repeat 0 0/cover;
}
.country__badge__italie {
  background: url(../../web/img/it.png) no-repeat 0 0/cover;
}
.country__badge__kroatie {
  background: url(../../web/img/hr.png) no-repeat 0 0/cover;
}
.country__badge__luxemburg {
  background: url(../../web/img/lu.png) no-repeat 0 0/cover;
}
.country__badge__marokko {
  background: url(../../web/img/ma.png) no-repeat 0 0/cover;
}
.country__badge__nederland {
  background: url(../../web/img/nl.png) no-repeat 0 0/cover;
}
.country__badge__noorwegen {
  background: url(../../web/img/no.png) no-repeat 0 0/cover;
}
.country__badge__oostenrijk {
  background: url(../../web/img/at.png) no-repeat 0 0/cover;
}
.country__badge__portugal {
  background: url(../../web/img/pt.png) no-repeat 0 0/cover;
}
.country__badge__slovenie {
  background: url(../../web/img/sl.png) no-repeat 0 0/cover;
}
.country__badge__spanje {
  background: url(../../web/img/sp.png) no-repeat 0 0/cover;
}
.country__badge__tsjechie {
  background: url(../../web/img/cz.png) no-repeat 0 0/cover;
}
.country__badge__zweden {
  background: url(../../web/img/se.png) no-repeat 0 0/cover;
}
.country__badge__zwitserland {
  background: url(../../web/img/ch.png) no-repeat 0 0/cover;
}

.tag_Hond,
.tag_Hund,
.tag_Dog {
  font-size: 0 !important;
  height: 25px;
}
.tag_Hond:before,
.tag_Hund:before,
.tag_Dog:before {
  font-family: Line Awesome Free;
  font-weight: 900;
  font-size: 20px;
  content: "\f6d3";
  position: relative;
  line-height: 13px;
  top: 3px;
}

.sitetheme--ewr .tag_route {
  display: none;
}

.tag_route {
  font-size: 0 !important;
  height: 25px;
}
.tag_route:before {
  font-family: Line Awesome Free;
  font-weight: 900;
  font-size: 20px;
  content: "\f02d";
  position: relative;
  line-height: 13px;
  top: 3px;
}

.tag_zwembad {
  font-size: 0 !important;
  height: 25px;
}
.tag_zwembad:before {
  font-family: Line Awesome Free;
  font-weight: 900;
  font-size: 20px;
  content: "\f5c4";
  position: relative;
  line-height: 13px;
  top: 3px;
}

.triptype_fietsvakantie .tag_Zwaarte,
.triptype_bike .tag_Zwaarte,
.triptype_cyclingholiday .tag_Zwaarte,
.triptype_radreise .tag_Zwaarte,
.triptype_rad-undschiffsreise .tag_Zwaarte,
.triptype_boatandbiketour .tag_Zwaarte {
  border-color: transparent;
  font-size: 0 !important;
  background-image: url(../../web/img/icons/icon-zwaarte-grijs.png);
  height: 18px;
  background-position: 84px 0;
  background-size: 156px;
  background-repeat: no-repeat;
  position: relative;
  top: 2px;
  width: 244px;
}
.triptype_fietsvakantie .tag_Zwaarte:before,
.triptype_bike .tag_Zwaarte:before,
.triptype_cyclingholiday .tag_Zwaarte:before,
.triptype_radreise .tag_Zwaarte:before,
.triptype_rad-undschiffsreise .tag_Zwaarte:before,
.triptype_boatandbiketour .tag_Zwaarte:before {
  font-family: Line Awesome Free;
  font-weight: 900;
  font-size: 22px;
  content: "\f206";
  left: 0;
  top: 0;
  position: relative;
  background-position: 84px 0;
  background-size: 156px;
  background-repeat: no-repeat;
}
.triptype_fietsvakantie .zwaarte_1:before,
.triptype_bike .zwaarte_1:before,
.triptype_cyclingholiday .zwaarte_1:before,
.triptype_radreise .zwaarte_1:before,
.triptype_rad-undschiffsreise .zwaarte_1:before,
.triptype_boatandbiketour .zwaarte_1:before {
  background-image: url(../../web/img/icons/icon-zwaarte.png);
  width: 115px;
  position: absolute;
}
.triptype_fietsvakantie .zwaarte_12:before,
.triptype_bike .zwaarte_12:before,
.triptype_cyclingholiday .zwaarte_12:before,
.triptype_radreise .zwaarte_12:before,
.triptype_rad-undschiffsreise .zwaarte_12:before,
.triptype_boatandbiketour .zwaarte_12:before {
  background-image: url(../../web/img/icons/icon-zwaarte.png);
  width: 132px;
  position: absolute;
}
.triptype_fietsvakantie .zwaarte_2:before,
.triptype_bike .zwaarte_2:before,
.triptype_cyclingholiday .zwaarte_2:before,
.triptype_radreise .zwaarte_2:before,
.triptype_rad-undschiffsreise .zwaarte_2:before,
.triptype_boatandbiketour .zwaarte_2:before {
  background-image: url(../../web/img/icons/icon-zwaarte.png);
  width: 147px;
  position: absolute;
}
.triptype_fietsvakantie .zwaarte_23:before,
.triptype_bike .zwaarte_23:before,
.triptype_cyclingholiday .zwaarte_23:before,
.triptype_radreise .zwaarte_23:before,
.triptype_rad-undschiffsreise .zwaarte_23:before,
.triptype_boatandbiketour .zwaarte_23:before {
  background-image: url(../../web/img/icons/icon-zwaarte.png);
  width: 164px;
  position: absolute;
}
.triptype_fietsvakantie .zwaarte_3:before,
.triptype_bike .zwaarte_3:before,
.triptype_cyclingholiday .zwaarte_3:before,
.triptype_radreise .zwaarte_3:before,
.triptype_rad-undschiffsreise .zwaarte_3:before,
.triptype_boatandbiketour .zwaarte_3:before {
  background-image: url(../../web/img/icons/icon-zwaarte.png);
  width: 178px;
  position: absolute;
}
.triptype_fietsvakantie .zwaarte_34:before,
.triptype_bike .zwaarte_34:before,
.triptype_cyclingholiday .zwaarte_34:before,
.triptype_radreise .zwaarte_34:before,
.triptype_rad-undschiffsreise .zwaarte_34:before,
.triptype_boatandbiketour .zwaarte_34:before {
  background-image: url(../../web/img/icons/icon-zwaarte.png);
  width: 194px;
  position: absolute;
}
.triptype_fietsvakantie .zwaarte_4:before,
.triptype_bike .zwaarte_4:before,
.triptype_cyclingholiday .zwaarte_4:before,
.triptype_radreise .zwaarte_4:before,
.triptype_rad-undschiffsreise .zwaarte_4:before,
.triptype_boatandbiketour .zwaarte_4:before {
  background-image: url(../../web/img/icons/icon-zwaarte.png);
  width: 208px;
  position: absolute;
}
.triptype_fietsvakantie .zwaarte_45:before,
.triptype_bike .zwaarte_45:before,
.triptype_cyclingholiday .zwaarte_45:before,
.triptype_radreise .zwaarte_45:before,
.triptype_rad-undschiffsreise .zwaarte_45:before,
.triptype_boatandbiketour .zwaarte_45:before {
  background-image: url(../../web/img/icons/icon-zwaarte.png);
  width: 225px;
  position: absolute;
}
.triptype_fietsvakantie .zwaarte_5:before,
.triptype_bike .zwaarte_5:before,
.triptype_cyclingholiday .zwaarte_5:before,
.triptype_radreise .zwaarte_5:before,
.triptype_rad-undschiffsreise .zwaarte_5:before,
.triptype_boatandbiketour .zwaarte_5:before {
  background-image: url(../../web/img/icons/icon-zwaarte.png);
  width: 244px;
  position: absolute;
}

.triptype_fietsvakantie .tag_Zwaarte:after {
  content: "Zwaarte";
  font-size: 14px;
  position: relative;
  left: 21px;
}

.triptype_bike .tag_Zwaarte:after,
.triptype_cyclingholiday .tag_Zwaarte:after,
.triptype_boatandbiketour .tag_Zwaarte:after {
  content: "Difficulty";
  font-size: 14px;
  position: relative;
  left: 21px;
}

html[lang=de] .triptype_bike .tag_Zwaarte:after,
.triptype_radreise .tag_Zwaarte:after,
.triptype_rad-undschiffsreise .tag_Zwaarte:after {
  content: "Schwere";
  font-size: 14px;
  position: relative;
  left: 21px;
}

.triptype_wandelvakantie .tag_Zwaarte,
.triptype_walk .tag_Zwaarte {
  border-color: transparent;
  font-size: 0 !important;
  background-image: url(../../web/img/icons/icon-zwaarte-grijs.png);
  height: 18px;
  background-position: 84px 0;
  background-size: 156px;
  background-repeat: no-repeat;
  position: relative;
  top: 2px;
  width: 244px;
}
.triptype_wandelvakantie .tag_Zwaarte:before,
.triptype_walk .tag_Zwaarte:before {
  font-family: Line Awesome Free;
  font-weight: 900;
  font-size: 22px;
  content: "\f554";
  left: 0;
  top: 0;
  position: relative;
  background-position: 84px 0;
  background-size: 156px;
  background-repeat: no-repeat;
}
.triptype_wandelvakantie .tag_Zwaarte:after,
.triptype_walk .tag_Zwaarte:after {
  content: "Zwaarte";
  font-size: 14px;
  position: relative;
  left: 21px;
}
.triptype_wandelvakantie .zwaarte_1:before,
.triptype_walk .zwaarte_1:before {
  background-image: url(../../web/img/icons/icon-zwaarte.png);
  width: 115px;
  position: absolute;
}
.triptype_wandelvakantie .zwaarte_12:before,
.triptype_walk .zwaarte_12:before {
  background-image: url(../../web/img/icons/icon-zwaarte.png);
  width: 132px;
  position: absolute;
}
.triptype_wandelvakantie .zwaarte_2:before,
.triptype_walk .zwaarte_2:before {
  background-image: url(../../web/img/icons/icon-zwaarte.png);
  width: 147px;
  position: absolute;
}
.triptype_wandelvakantie .zwaarte_23:before,
.triptype_walk .zwaarte_23:before {
  background-image: url(../../web/img/icons/icon-zwaarte.png);
  width: 164px;
  position: absolute;
}
.triptype_wandelvakantie .zwaarte_3:before,
.triptype_walk .zwaarte_3:before {
  background-image: url(../../web/img/icons/icon-zwaarte.png);
  width: 178px;
  position: absolute;
}
.triptype_wandelvakantie .zwaarte_34:before,
.triptype_walk .zwaarte_34:before {
  background-image: url(../../web/img/icons/icon-zwaarte.png);
  width: 194px;
  position: absolute;
}
.triptype_wandelvakantie .zwaarte_4:before,
.triptype_walk .zwaarte_4:before {
  background-image: url(../../web/img/icons/icon-zwaarte.png);
  width: 208px;
  position: absolute;
}
.triptype_wandelvakantie .zwaarte_45:before,
.triptype_walk .zwaarte_45:before {
  background-image: url(../../web/img/icons/icon-zwaarte.png);
  width: 225px;
  position: absolute;
}
.triptype_wandelvakantie .zwaarte_5:before,
.triptype_walk .zwaarte_5:before {
  background-image: url(../../web/img/icons/icon-zwaarte.png);
  width: 244px;
  position: absolute;
}

.tripdeal__label {
  position: absolute;
  background-color: var(--red);
  font-size: 14px;
  font-weight: 600;
  color: var(--white);
  top: 0;
  right: 0;
  padding: 3px 8px;
  line-height: 20px;
}

@media (max-width: 991.98px) {
  .trips__result .tripdeal__label {
    right: 1.5rem;
    top: 1.5rem;
    z-index: 1;
  }
}