#mapboxModal .ewr__searchmap {
  margin-top: 0 !important;
}
#mapboxModal .ewr__searchmap .circle {
  display: none;
}

.ewr__searchmap {
  max-height: 985px;
  position: relative;
  z-index: 0;
}
.ewr__searchmap .circle {
  border: 20px solid #f4f8fb;
  position: absolute;
  width: 200px;
  height: 200px;
  text-align: center;
  border-radius: 100%;
  top: -50px;
  left: 10px;
  transform: rotate(-9deg);
  padding: 37px 5px;
  z-index: 1;
}
.ewr__searchmap .circle span {
  font-family: var(--theme-handwriting-font);
  font-size: 40px;
  line-height: 44px;
  display: block;
}
@media (max-width: 767px) {
  .ewr__searchmap .circle {
    border: 10px solid #f4f8fb;
    width: 120px;
    height: 120px;
    top: -64px;
    left: 15px;
    padding: 22px 14px;
  }
  .ewr__searchmap .circle span {
    font-size: 26px;
    line-height: 28px;
  }
}

.ewr__searchmap__filter {
  background: var(--secondary);
  display: flex;
  flex-direction: column;
  position: absolute;
  right: 75px;
  top: 30px;
  width: 180px;
  z-index: 2;
}
.ewr__searchmap__filter .aside__title,
.ewr__searchmap__filter .form-check-label,
.ewr__searchmap__filter .form-label {
  color: var(--white);
}

html.sitetheme--hch .ewr__searchmap .circle span {
  line-height: 36px;
}