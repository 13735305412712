.ewr__video__header {
  overflow: hidden;
  position: relative;
  height: 70vh;
  background-image: url(../../web/img/ewr-videoheader-bg.jpg);
  background-size: cover;
  background-position: center center;
}
.ewr__video__header.nobg {
  background-image: none;
}
@media (max-width: 565px) {
  .ewr__video__header {
    height: 60vh;
  }
}
.ewr__video__header iframe {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 177.78vh;
  height: 100vh;
  transform: translate(-50%, -50%);
  pointer-events: none;
}
.ewr__video__header:before {
  content: "";
  background: rgba(1, 13, 38, 0.1);
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
  top: 0;
}
.ewr__video__header .trips__search {
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
  height: 100%;
}
@media (max-width: 565px) {
  .ewr__video__header .trips__search {
    height: 90%;
  }
}
.ewr__video__header .trips__search__block {
  background-color: var(--videoblock);
  background-blend-mode: multiply;
  padding: 30px;
}
.ewr__video__header .trips__search__title {
  color: #fff;
  font-size: 42px;
  line-height: 1.2;
  margin-bottom: 1.5rem;
  display: block;
  font-family: var(--theme-heading-font);
}
@media (max-width: 565px) {
  .ewr__video__header .trips__search__title {
    font-size: 36px;
  }
}
.ewr__video__header .trips__search__form label {
  color: var(--white);
}
.ewr__video__header .trips__search__form .form-control,
.ewr__video__header .trips__search__form .form-select {
  border: 0;
}