html {
  position: relative;
  min-height: 100%;
  scroll-behavior: smooth;
}

html,
body {
  background-color: var(--light);
}

main {
  width: 100%;
  max-width: 20in;
  margin: 0 auto;
  background-color: #fff;
}

.pagetype--isrtd .ewr_headerimage {
  max-height: 400px;
  overflow: hidden;
}

.pagetype--ishome .ewr__searchmap #filter_ebike,
.pagetype--ishome .ewr__searchmap #filter_vegetarian {
  display: none;
}
@media (max-width: 991.98px) {
  .pagetype--ishome .ewr__searchmap {
    display: none;
  }
}
.pagetype--ishome[data-wh-consent=granted] .ewr__video__header {
  background-image: none;
}

.rounded-sm {
  border-radius: 0.25rem;
}

.rounded-lg {
  border-radius: 0.5rem;
}

.container,
.container-xl {
  transition: all 0.3s ease;
}

.bg-light {
  background-color: var(--light) !important;
}

.bg-dark {
  background-color: var(--dark) !important;
}
.bg-dark > * {
  color: var(--white);
}

.bg-white {
  background-color: var(--white) !important;
}

.bg-primary {
  background-color: var(--primary) !important;
}
.bg-primary > * {
  color: var(--white);
}

.bg-primary-light {
  background-color: var(--primary-light) !important;
}

.bg-secondary {
  background-color: var(--secondary) !important;
}
.bg-secondary > * {
  color: var(--white);
}

.bg-secondary-light {
  background-color: var(--secondary-light) !important;
}

.la-star-half-alt,
.la-star {
  color: var(--orange);
}

.form-control,
.form-select {
  padding: 0.5rem 1rem;
  border-radius: 4px;
}

.form-check-input:checked {
  background-color: var(--primary);
  border-color: var(--primary);
}

.website--showcookiebar:after {
  content: "";
  background-color: rgba(0, 0, 0, 0.65);
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 1020;
  pointer-events: none;
}

.cookiebar__notice {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 500px;
  min-width: 350px;
  z-index: 1030;
  background-color: #fff;
  padding: 20px;
  box-shadow: 0px 0px 60px rgba(0, 0, 0, 0.5);
}
.cookiebar__notice .btn-link {
  color: var(--primary);
  vertical-align: baseline;
  border: 0;
  font-size: inherit;
}
.cookiebar__notice .accept-cookie {
  min-width: 200px;
  text-align: center;
}
@media (max-width: 575px) {
  .cookiebar__notice .accept-cookie {
    width: 100%;
  }
}
.cookiebar__notice a {
  text-decoration: underline;
  color: var(--primary);
}

.grecaptcha-badge {
  visibility: hidden;
}

#custom-notification {
  text-align: center;
  background: var(--red);
  color: var(--white);
  top: 0;
  left: 0;
  right: 0;
  z-index: 10000000;
  height: 0;
  position: relative;
  overflow: hidden;
  max-height: 100vh;
}
#custom-notification .container {
  padding: 10px 0;
}
#custom-notification .container p {
  margin-bottom: 0;
}
#custom-notification a {
  text-decoration: underline;
  color: var(--white);
  display: inline-block;
  margin-left: 5px;
}
#custom-notification button {
  font-size: 18px;
  position: absolute;
  right: 0;
  top: 0;
  color: var(--white);
}