.wh-pagination {
  list-style: none;
  margin: 0;
  padding: 0;
  border: 0 none;
}
.wh-pagination li {
  display: inline-block;
  border: 1px solid #ddd;
  text-align: center;
  border-radius: 3px;
}
.wh-pagination li + li {
  margin-left: 5px;
}
.wh-pagination span, .wh-pagination a {
  display: block;
  padding: 5px 12px;
}
.wh-pagination a {
  color: var(--secondary);
  text-decoration: none;
}
.wh-pagination a:hover {
  background-color: #eee;
}
.wh-pagination li.active {
  border: 1px solid var(--secondary);
}
.wh-pagination li.active a {
  background-color: var(--secondary);
  color: #fff;
}
.wh-pagination span::before {
  content: "...";
  display: inline-block;
}

.ewr-posts-nav {
  padding-top: 20px;
  padding-bottom: 20px;
  border-top: 1px solid var(--light);
}
@media (max-width: 768px) {
  .ewr-posts-nav .row .col-6.text-start {
    border-right: 1px solid var(--light);
  }
}
.ewr-posts-nav .row .col-6.text-start a {
  justify-content: start;
}
.ewr-posts-nav .row .col-6 a {
  display: flex;
  align-items: center;
  text-decoration: none;
  justify-content: end;
}
.ewr-posts-nav .row .col-6 h5,
.ewr-posts-nav .row .col-6 strong {
  margin: 0;
  font-size: 1rem;
}
@media (max-width: 768px) {
  .ewr-posts-nav .row .col-6 h5,
  .ewr-posts-nav .row .col-6 strong {
    font-size: 14px;
  }
}
.ewr-posts-nav .row .col-6 .ewr-posts-nav__thumbnail {
  display: block;
  margin: 15px;
}
.ewr-posts-nav .row .col-6 .ewr-posts-nav__thumbnail img {
  border-radius: 10px;
  max-width: 100px;
  max-height: 100px;
}