.ewr__contact .travel__agent {
  position: absolute;
  left: -85px;
  top: 34px;
}
@media (max-width: 992px) {
  .ewr__contact .travel__agent {
    left: 0;
    top: -111px;
    right: 0;
    margin: 0 auto;
    width: 192px;
    height: 192px;
  }
}
@media (max-width: 992px) {
  .ewr__contact .position-relative {
    margin-top: 100px;
  }
}
.ewr__contact .bg-primary-light {
  padding: 60px 40px 40px 110px;
}
@media (max-width: 992px) {
  .ewr__contact .bg-primary-light {
    padding: 85px 40px 40px 40px;
  }
}
.ewr__contact .bg-primary-light h4 {
  font-size: 1.7rem;
}