@media (min-width: 992px) {
  .scroll-down header#masthead {
    transform: none;
    position: sticky;
    top: 0;
  }
  .scroll-up header#masthead {
    transform: none;
    position: sticky;
    top: 0;
  }
}
@media (max-width: 991.98px) {
  .scroll-down header#masthead {
    transform: translate3d(0, -100%, 0);
  }
  .scroll-up header#masthead {
    transform: none;
    position: sticky;
    top: 0;
  }
}
html:not(.with--headerimage) #masthead.headerbottom:after {
  content: "";
  display: block;
  height: 45px;
  background-color: var(--light);
  margin-bottom: 30px;
  box-shadow: inset 0px -23px 39px -25px rgba(0, 0, 0, 0.1);
}

html.pagetype--isthemeoverview #masthead:after {
  content: "";
  display: block;
  height: 45px;
  background-color: var(--light);
  margin-bottom: 30px;
  box-shadow: inset 0px -23px 39px -25px rgba(0, 0, 0, 0.1);
}

#masthead {
  z-index: 1010;
  transition: all 0.3s ease;
}
@media (max-width: 565px) {
  #masthead .topbar button[data-bs-target="#sidenavEwr"] {
    position: relative;
    z-index: 1012;
  }
}
#masthead .topbar .logo {
  position: relative;
  display: block;
}
#masthead .topbar .logo img {
  position: relative;
  z-index: 1012;
  top: 20px;
}
@media (max-width: 991.98px) {
  #masthead .topbar .logo img {
    top: 0;
    width: 175px;
  }
}
#masthead .topbar .logo:after {
  content: "";
  display: block;
  width: 310px;
  height: 310px;
  border-radius: 100%;
  background-color: var(--white);
  position: absolute;
  z-index: 1011;
  top: -180px;
  left: -50px;
}
@media (max-width: 991.98px) {
  #masthead .topbar .logo:after {
    left: 50%;
    transform: translateX(-50%);
    width: 275px;
    height: 275px;
  }
}
#masthead .topbar__search {
  width: 40%;
}
#masthead .topbar__search .form-control {
  border-color: var(--secondary);
  border-right-width: 0;
}
#masthead .topbar__search .btn-link {
  border: 1px solid var(--secondary);
  border-left-width: 0;
  color: var(--font-color);
}
#masthead .topbar__reviews {
  width: 60%;
}
#masthead .topbar__reviews .las {
  color: var(--orange);
}
#masthead .topbar__reviews small {
  display: block;
  line-height: 1;
}
#masthead .topbar__reviews small .review__totals {
  font-weight: 600;
}
#masthead .topbar__languageswitch {
  padding: 0 0 0 15px;
  position: relative;
}
#masthead .topbar__languageswitch ul li a {
  font-size: 16px;
  display: inline-block;
  line-height: 23px;
  color: var(--font-color);
  text-decoration: none;
}
#masthead .topbar__languageswitch ul li .dropdown-menu {
  padding: 0;
  min-width: 50px;
  right: 0;
}
#masthead .topbar .btn-link .las {
  color: var(--font-color);
  font-size: 30px;
}
#masthead .topbar .btn-link .las.la-bars {
  background: url('data:image/svg+xml,<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 346.6 346.6" xml:space="preserve"><g><path fill="%23064152" d="M31.8,189.5c-2.7,0-5.6-0.2-8.1-1c-7.3-2.4-11.4-9.7-10.3-17.5c1-7.1,7.2-12.8,14.6-13.2c1.4-0.1,2.7-0.1,4.1-0.1c93.8,0,187.7,0,281.5,0c2.8,0,5.8,0.1,8.5,1c7.3,2.4,11.4,9.7,10.3,17.5c-1,7.1-7.2,12.8-14.6,13.2C316.2,189.6,78.7,189.6,31.8,189.5z"/><path fill="%23064152" d="M110.5,93.7c-9.7,0-16.1-5.3-17.3-14.1c-1.2-8.4,5.3-16.6,13.7-17.5c1.5-0.2,3-0.2,4.5-0.2c67.7,0,135.5,0,203.2,0c2.2,0,4.5,0.1,6.7,0.6c7.7,2.1,12.3,9.4,11.3,17.5c-0.9,7.4-7.3,13.4-15.1,13.5C302.7,93.8,144.5,93.7,110.5,93.7z"/><path fill="%23064152" d="M316.1,253.6c11.7,0,19.4,10.5,15.7,21.2c-2.2,6.1-7.6,10.3-14.1,10.5c-4.4,0.2-8.7,0.1-13.1,0.1c-42.9,0-85.9,0-128.8,0c-2.5,0-5,0-7.4-0.7c-7.7-2.2-12.2-9.4-11.2-17.6c0.9-7.5,7.5-13.4,15.5-13.5C196.8,253.5,292.4,253.5,316.1,253.6z"/></g></svg>');
  width: 28px;
  height: 28px;
}
#masthead .topbar .btn-link .las.la-bars:before {
  display: none;
}
@media (min-width: 992px) {
  #masthead .dropdown {
    position: static;
  }
  #masthead .dropdown .dropdown-toggle:after {
    content: "\f107";
    border: none;
    font-family: Line Awesome Free;
    font-weight: 900;
    font-size: 12px;
    position: relative;
    top: 2px;
  }
  #masthead .dropdown:hover .dropdown-menu {
    display: block;
  }
  #masthead .dropdown .dropdown-menu[data-bs-popper] {
    left: inherit;
  }
  #masthead .dropdown .dropdown-menu.row {
    min-width: 30rem;
  }
  #masthead .dropdown .dropdown-menu.row .col-lg-8,
  #masthead .dropdown .dropdown-menu.row .col-lg-4 {
    float: left;
  }
  #masthead .dropdown .dropdown-menu.row .col-lg-8 .columns,
  #masthead .dropdown .dropdown-menu.row .col-lg-12 .columns {
    column-count: 3;
  }
}
#masthead .nav-item .nav-link {
  color: var(--white);
  font-family: var(--theme-font);
  font-size: 16px;
  line-height: 24px;
  position: relative;
  padding: 0.7rem 0;
}
#masthead .nav-item .nav-link:focus, #masthead .nav-item .nav-link:hover {
  color: var(--secondary-light);
}
#masthead .nav-item.active .nav-link {
  color: var(--primary);
}
#masthead .nav-item:not(:last-child) {
  margin-right: 1.2rem;
}
#masthead .dropdown-menu {
  border: none;
  border-radius: 0;
  box-shadow: 4px 7px 10px rgba(0, 0, 0, 0.15) !important;
}
#masthead .dropdown-menu.columns {
  column-count: 3;
}
#masthead .dropdown-menu .dropdown-item {
  font-family: var(--theme-font);
  color: var(--font-color);
  display: block;
}
#masthead .dropdown-menu .dropdown-item:not(:last-child) {
  margin-right: 0;
}
#masthead .dropdown-menu .dropdown-item:hover, #masthead .dropdown-menu .dropdown-item:focus {
  background-color: var(--white);
  text-decoration: underline;
}
#masthead .dropdown-menu .current .dropdown-item {
  background-color: var(--primary);
  color: #fff !important;
}
#masthead .btn-group .searchtoggle {
  color: var(--font-color);
}
#masthead .btn-group .searchtoggle:hover, #masthead .btn-group .searchtoggle:focus {
  color: var(--font-color);
}
@media (max-width: 991.98px) {
  #masthead .btn-group .la-bars {
    color: var(--font-color);
  }
}
#masthead #searchcollapse .form-control {
  border: none;
}

@media (min-width: 992px) {
  .menu-item-has-children:hover .dropdown-menu {
    display: block;
  }
}
@media (min-width: 768px) {
  .animate {
    animation-duration: 0.3s;
    -webkit-animation-duration: 0.3s;
    animation-fill-mode: both;
    -webkit-animation-fill-mode: both;
  }
}
@keyframes slideIn {
  0% {
    transform: translateY(1rem);
    opacity: 0;
  }
  100% {
    transform: translateY(0rem);
    opacity: 1;
  }
  0% {
    transform: translateY(1rem);
    opacity: 0;
  }
}
@-webkit-keyframes slideIn {
  0% {
    -webkit-transform: transform;
    -webkit-opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    -webkit-opacity: 1;
  }
  0% {
    -webkit-transform: translateY(1rem);
    -webkit-opacity: 0;
  }
}
.slideIn {
  -webkit-animation-name: slideIn;
  animation-name: slideIn;
}