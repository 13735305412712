.wh-searchpage #masthead.headerbottom:after {
  margin-bottom: 0;
}
.wh-searchpage__root {
  padding: 50px 0;
}
.wh-searchpage__root .wh-searchpage__results .wh-searchpage__resultlist {
  list-style: none;
  padding: 0;
}
.wh-searchpage__root .wh-searchpage__results .wh-searchpage__resultlist article h2 a {
  color: var(--font-color);
  text-decoration: none;
}
.wh-searchpage__root .wh-searchpage__results .wh-searchpage__resultlist article h2 a:hover, .wh-searchpage__root .wh-searchpage__results .wh-searchpage__resultlist article h2 a:focus {
  text-decoration: underline;
}
.wh-searchpage__root .consilio--highlight {
  font-weight: bold;
}