html.sitetheme--hch 
{
  --theme-heading-font: 'Tilt Warp', sans-serif;
  --theme-handwriting-font: 'a_affirmationregular', sans-serif;
  --theme-font: 'PT Sans', sans-serif;

  --primary: #f26712;
  --primary-light: #fdede2;
  --primary-hover: #ff761f;
  --primary-dark: #f15401;
  --secondary: #292663;
  --secondary-light: #f2f2f7;
  --secondary-hover: #433f8c;
  --secondary-dark: #042d38;
  --white: #fff;
  --black: #000;
  --light: #f2f2f7;
  --dark: #292663;
  --dark-hover: #222222;
  --font-color: #292663;
  --border-color: #292663;
  --border-color-light: #d2d2e0;

  --darkblue: rgba(41, 38, 99, 1);
  --blue: rgba(9, 175, 214, 1);
  --lightblue: rgba(216, 236, 247, 1);
  --darkgreen: rgba(42, 151, 59, 1);
  --green: rgba(111, 182, 57, 1);
  --lightgreen: rgba(230, 242, 232, 1);
  --brown: rgba(176, 109, 37, 1);
  --lightbrown: rgba(236, 219, 180, 1);
  --orange: rgba(239, 125, 0, 1);
  --lightorange: rgba(254, 231, 209, 1);
  --red: rgba(175, 37, 54, 1);
  --lightred: rgba(235, 197, 193, 1);

  --videoblock: rgba(41, 38, 99, .7);
  --darkblue-a: rgba(41, 38, 99, .8);
  --blue-a: rgba(9, 175, 214, .8);
  --lightblue-a: rgba(216, 236, 247, .8);
  --darkgreen-a: rgba(42, 151, 59, .8);
  --green-a: rgba(111, 182, 57, .8);
  --lightgreen-a: rgba(230, 242, 232, .8);
  --brown-a: rgba(176, 109, 37, .8);
  --lightbrown-a: rgba(236, 219, 180, .8);
  --orange-a: rgba(239, 125, 0, .8);
  --lightorange-a: rgba(254, 231, 209, .8);
  --red-a: rgba(175, 37, 54, .8);
  --lightred-a: rgba(235, 197, 193, .8);
}

html.sitetheme--ewr 
{
  --theme-heading-font: 'Tilt Warp', sans-serif;
  --theme-handwriting-font: 'a_affirmationregular', sans-serif;
  --theme-font: 'PT Sans', sans-serif;

  --primary: #2a973b;
  --primary-light: #e6f2e8;
  --primary-hover: #23a638;
  --primary-dark: #237d30;
  --secondary: #064152;
  --secondary-light: #f2f2f7;
  --secondary-hover: #2b5d6c;
  --secondary-dark: #042d38;
  --white: #fff;
  --black: #000;
  --light: #f2f2f7;
  --dark: #064152;
  --dark-hover: #222222;
  --font-color: #064152;
  --border-color: #064152;
  --border-color-light: #cdd9dc;

  --darkblue: rgba(6, 65, 82, 1);
  --blue: rgba(9, 175, 214, 1);
  --lightblue: rgba(216, 236, 247, 1);
  --darkgreen: rgba(42, 151, 59, 1);
  --green: rgba(111, 182, 57, 1);
  --lightgreen: rgba(230, 242, 232, 1);
  --brown: rgba(176, 109, 37, 1);
  --lightbrown: rgba(236, 219, 180, 1);
  --orange: rgba(239, 125, 0, 1);
  --lightorange: rgba(254, 231, 209, 1);
  --red: rgba(175, 37, 54, 1);
  --lightred: rgba(235, 197, 193, 1);

  --videoblock: rgba(10, 43, 52, .7);
  --darkblue-a: rgba(6, 65, 82, .8);
  --blue-a: rgba(9, 175, 214, .8);
  --lightblue-a: rgba(216, 236, 247, .8);
  --darkgreen-a: rgba(42, 151, 59, .8);
  --green-a: rgba(111, 182, 57, .8);
  --lightgreen-a: rgba(230, 242, 232, .8);
  --brown-a: rgba(176, 109, 37, .8);
  --lightbrown-a: rgba(236, 219, 180, .8);
  --orange-a: rgba(239, 125, 0, .8);
  --lightorange-a: rgba(254, 231, 209, .8);
  --red-a: rgba(175, 37, 54, .8);
  --lightred-a: rgba(235, 197, 193, .8);
}

/* Add import statement for custom font here */
@font-face {
    font-family: 'a_affirmationregular';
    src: url('../../web/fonts/aaffirmation-webfont.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Line Awesome Free';
    src: url('../../web/fonts/la-solid-900.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
@font-face {
  font-family: 'Tilt Warp';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(../../web/fonts/tiltwarp.woff2) format('woff2');
}
@font-face {
  font-family: 'PT Sans';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(../../web/fonts/ptsans-regular.woff2) format('woff2');
}
@font-face {
  font-family: 'PT Sans';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(../../web/fonts/ptsans-bold.woff2) format('woff2');
}
body
{
  font-family:'PT Sans', sans-serif;
  color: var(--dark);
  font-weight:400;
  font-size: 19px;
  line-height:28px;
}
@media (max-width:565px)
{
  body
  {
    font-size: 16px;
  }
}
h1,
h2,
h3,
h4,
h5,
h6 
{
    font-family: 'Tilt Warp', sans-serif;
    color: var(--dark);
    font-weight: normal;
    margin-bottom: 15px;
}
.lead
{
  font-weight:600;
}
@media (min-width:992px)
{
  .section-title,
  .sectiontitle1,
  .sectiontitle2,
  .sectiontitle3
  {
    font-size:3rem;
  }
}
.handwritten_title,
.handwritten
{
    font-family: 'a_affirmationregular', sans-serif;
    font-size:42px;
    margin: 0;
    display: inline-block;
    line-height: 36px;
    color:var(--primary);
}
p 
{

    margin-bottom: 1em;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
a 
{
    color: var(--primary);
    text-decoration: underline;
}
html.with--headerimage .ewr_headerimage
{
  margin-bottom:20px;
}
/* inline images */
.wh-rtd__img
{
  max-width: 100%;
  height: auto;
}
/* lists */
  ul.unordered
, ol.ordered
{
  margin: 0 0 20px;
  padding-bottom: 0;
  padding-top: 0;
  margin-left: 0;
  line-height: 160%;
}
ul.mapmarkers,
ul.checkmarks,
ul.kruisjes,
ul.arrow,
ul.angles,
ul.dashes,
ul.plusjes {
  list-style: none;
  padding:0;
  position: inherit;
  width: auto;
  height: auto;
}
ul.checkmarks li,
ul.kruisjes li,
ul.arrow li,
ul.angles li,
ul.dashes li,
ul.plusjes li 
{
  display:table;
}
ul.mapmarkers li
{
  display:table;
  margin-bottom: 15px;
}
ul.checkmarks li:before {
    content:"";
    background:url('data:image/svg+xml,<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 512 512" xml:space="preserve"><g><path fill="%232A973B" d="M191.9,304.6c2.3-2.8,3.4-4.4,4.8-5.8c64.9-64.9,129.7-129.8,194.6-194.6c16.2-16.2,34.2-16.2,50.4,0c3.9,3.9,7.8,7.7,11.6,11.6c13.5,14,13.5,32.7-0.2,46.5c-20.2,20.4-40.5,40.5-60.7,60.8C331.2,284.2,270.1,345.3,209,406.5c-6.8,6.8-14.4,10.1-23.7,6.8c-3.3-1.2-6.5-3.4-9-5.9c-39.4-39.3-78.8-78.6-118.1-118C44.4,275.7,44.5,256.8,58,243c4.5-4.6,9.1-9.2,13.7-13.7c13.7-13.3,32.6-13.4,46.2,0.1c23.4,23.2,46.6,46.6,69.9,69.9C189.1,300.5,190,302.1,191.9,304.6z"/></g></svg>');
    width: 36px;
    height: 23px;
    background-size: 19px;
    background-position: 14px 5px;
    display: table-cell;
    background-repeat: no-repeat;
}
ul.dashes li:before {
    content:"";
    background: url('data:image/svg+xml,<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 512 512" xml:space="preserve"><path fill="%23DD0000" d="M448.7,302.1H67.3c-6.6,0-12-5.4-12-12v-72.3c0-6.6,5.4-12,12-12h381.4c6.6,0,12,5.4,12,12v72.3C460.7,296.7,455.3,302.1,448.7,302.1z"/></svg>');
    width: 36px;
    height: 23px;
    background-size: 19px;
    background-position: 14px 5px;
    display: table-cell;
    background-repeat: no-repeat;
}
ul.mapmarkers li:before
{
    content:"";
    background:url('data:image/svg+xml,<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve"><g><path fill="%232A973B" d="M253,460.6c-3.4-2.8-7.4-5.2-10.2-8.5c-34.1-39.7-65.5-81.5-91.6-127c-15.2-26.4-28.5-53.7-36.7-83.3c-12.4-44.3-6.6-86.1,19-124.3c24.4-36.2,58.8-58,102.3-64c42.5-5.9,80.9,4.9,114.1,32c29.3,23.9,46.7,54.9,52.6,92.4c4.6,29.2-0.9,56.9-11,84c-13.5,36.4-32.8,69.7-54.2,101.8c-20.6,30.9-43.3,60.3-67.6,88.4c-2.9,3.3-6.8,5.7-10.2,8.5C257.3,460.6,255.1,460.6,253,460.6z M255.7,274.4c40.3,0.7,74.1-32.5,74.8-73.6c0.7-41-32.3-74.8-73.7-75.5c-40.6-0.7-74.4,32.5-75,73.8C181.2,240.1,214.2,273.7,255.7,274.4z"/></g></svg>');
    width: 19px;
    height: 19px;
    background-position: -2px 2px;
    display: table-cell;
    background-repeat: no-repeat;
}
ul.arrow li:before,
ul.angles li:before,
ul.mapmarkers li:before,
ul.plusjes li:before
{
    font-family: Line Awesome Free;
    font-weight: 900;
    width: 1.5em;
    font-size: 18px;
    padding-left: 7px;
}
ul.arrow li:before
{
    content: '\f061'
}
ul.angles li:before
{
    content: '\f105';
}
ul.plusjes li:before
{
    content: '\f067';
}
.button-green a
{
    display: inline-block;
    width: auto;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    user-select: none;
    border:1px solid var(--primary);
    background-color: var(--primary);
    color: #fff;
    font-family: PT Sans,sans-serif;
    white-space: normal;
    font-weight: 700;
    padding: 0.5rem 1rem;
    border-radius: 4px;
    text-decoration: none;
    margin-bottom: 10px;
}
.button-green a:hover,
.button-green a:focus,
.button-green a:active,
.button-green a.active,
.button-green a.active:hover
{
  background-color: var(--primary-hover);
  border-color: var(--primary-hover);
  text-decoration: none;
}
.button-blue a
{
    display: inline-block;
    width: auto;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    user-select: none;
    border:1px solid var(--primary);
    background-color: var(--primary);
    color: #fff;
    font-family: PT Sans,sans-serif;
    white-space: normal;
    font-weight: 700;
    padding: 0.5rem 1rem;
    border-radius: 4px;
    text-decoration: none;
}
.button-blue a:hover,
.button-blue a:focus,
.button-blue a:active,
.button-blue a.active,
.button-blue a.active:hover
{
  background-color: var(--secondary-hover);
  border-color: var(--secondary-hover);
  text-decoration: none;
}
/* tables */
.wh-rtd__tablewrap
{
  overflow-x: auto;
  margin-bottom: 20px;
  max-width: 100%;
}
.wh-rtd__table td, .wh-rtd__table th
{
  vertical-align: top;
  text-align: left;
  padding: 5px 10px;
}
  .wh-rtd__table td > *:last-child
, .wh-rtd__table th > *:last-child
{
  margin-bottom: 0;
}
  .wh-rtd--hascolheader > *
, .wh-rtd--hasrowheader th
{
  font-weight: bold;
}
.wh-rtd__table tr > *
{
  border-bottom: 1px solid #000000;
}
.wh-rtd__table .wh-rtd--hascolheader > *
{
  border-bottom: 2px solid #000000;
}
