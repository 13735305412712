@media (min-width: 768px) {
  .ewr__featured-blogs .row {
    min-height: 500px;
  }
}
.ewr__featured-blogs .row .large-post {
  padding-left: calc(var(--bs-gutter-x) * 0.5) !important;
}
.ewr__featured-blogs .row .col-md-6:nth-child(2) div:first-child {
  margin-bottom: 0.75rem;
}
@media (max-width: 768px) {
  .ewr__featured-blogs .row .col-md-6:first-child {
    min-height: 60vw;
  }
  .ewr__featured-blogs .row .col-md-6:nth-child(2) {
    flex-wrap: wrap !important;
    flex-direction: unset !important;
    justify-content: space-between !important;
    margin-top: 0.25rem !important;
    min-height: 120vw;
  }
  .ewr__featured-blogs .row .col-md-6:nth-child(2) > div {
    flex-grow: none !important;
  }
  .ewr__featured-blogs .row .col-md-6:nth-child(2) > div:nth-child(2) {
    margin-top: 0.25rem !important;
  }
  .ewr__featured-blogs .row .col-md-6:nth-child(2) > div:first-child {
    margin-bottom: 0 !important;
  }
}
.ewr__featured-blogs .row a {
  text-decoration: none;
}
.ewr__featured-blogs .row a div.bg {
  background-position: center center;
  background-size: cover;
  height: 100%;
  transition: all 0.3s ease;
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: end;
  flex-direction: inherit;
}
.ewr__featured-blogs .row a div.bg h2 {
  position: absolute;
  z-index: 1;
  color: #fff;
}
@media (max-width: 768px) {
  .ewr__featured-blogs .row a div.bg h2 {
    position: relative;
  }
}
.ewr__featured-blogs .row a div.bg:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: inherit;
  transition: inherit;
}
.ewr__featured-blogs .row a div.bg:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: linear-gradient(to top, rgba(0, 0, 0, 0.65) 0%, transparent 100%);
}
.ewr__featured-blogs .row a div.bg:hover:before, .ewr__featured-blogs .row a div.bg:focus:before {
  transform: scale(1.05);
}