aside .aside__title {
  font-size: 24px;
  display: block;
  margin-bottom: 1rem;
  font-family: var(--theme-heading-font);
}

.ewr__trips__filter {
  padding: 25px;
  margin-bottom: 1.5rem;
  background-color: var(--secondary);
}
@media (max-width: 991.8px) {
  .ewr__trips__filter {
    padding: 0;
  }
}
.ewr__trips__filter .aside__title, .ewr__trips__filter .form-label,
.ewr__trips__filter .filter_options,
.ewr__trips__filter .form-check-label {
  color: var(--white);
}
.ewr__trips__filter .form-label {
  font-weight: 600;
}
.ewr__trips__filter .form-check-label {
  font-size: 16px;
  cursor: pointer;
}
.ewr__trips__filter .form-check-label input[type=checkbox] {
  position: relative;
  top: 2px;
}
.ewr__trips__filter .form-select {
  border: 0;
}